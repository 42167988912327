import { ReactComponent as SvgLogout } from "./icons/svgIcon/logout.svg";
import { ReactComponent as SvgChart } from "./icons/svgIcon/chart-bar-light.svg";
import { ReactComponent as SvgGavel } from "./icons/svgIcon/gavel-light.svg";
import { ReactComponent as SvgMegaphone } from "./icons/svgIcon/megaphone-light.svg";
import { ReactComponent as SvgEllipses } from "./icons/svgIcon/ellipses-icon.svg";
import { ReactComponent as SvgDesktop } from "./icons/svgIcon/desktop-icon.svg";
import { ReactComponent as SvgPhone } from "./icons/svgIcon/mobile-icon.svg";
import { ReactComponent as SvgTranslate } from "./icons/svgIcon/languages.svg";
import { ReactComponent as SvgUser } from "./icons/svgIcon/faGroup.svg";
import { ReactComponent as SvgPlan } from "./icons/svgIcon/tag.svg";
import { ReactComponent as SvgCompany } from "./icons/svgIcon/Company-1.svg";
import { ReactComponent as SvgCoupon } from "./icons/svgIcon/Coupon-1.svg";
import { ReactComponent as SvgCommission } from "./icons/svgIcon/Commission.svg";
import { ReactComponent as SvgRole } from "./icons/svgIcon/role2.svg";
import { ReactComponent as SvgFeature } from "./icons/svgIcon/features3.svg";
import { ReactComponent as SvgSlides } from "./icons/svgIcon/slides.svg";
import { ReactComponent as SvgUpArrow } from "./icons/svgIcon/upArrow.svg";
import { ReactComponent as SvgDownArrow } from "./icons/svgIcon/downArrow.svg";
import { ReactComponent as SvgFilterIcon } from "./icons/svgIcon/clearFilter.svg";
import { ReactComponent as SvgPendingUser } from "./icons/svgIcon/pendingUser.svg";
import { ReactComponent as SvgVehicleReservationHistory } from "./icons/svgIcon/VehicleReservationHistory.svg";
import { ReactComponent as SvgEditVehicle } from "./icons/svgIcon/EditVehicle.svg";
import { ReactComponent as SvgImport } from "./icons/svgIcon/Import.svg";
import { ReactComponent as SvgLegal } from "./icons/svgIcon/Legal.svg";
import { ReactComponent as SvgAds } from "./icons/faDash.svg";
import { ReactComponent as SvgInvoice } from "./icons//svgIcon/invoice.svg";
import { ReactComponent as SvgFaq } from "./icons//svgIcon/faq.svg";
import { ReactComponent as SvgAdsIcon } from "./icons//svgIcon/ads.svg";
import { ReactComponent as SvgBidsIcon } from "./icons//svgIcon/bids.svg";
import { ReactComponent as SvgPartnersIcon } from "./icons//svgIcon/partners.svg";
import { ReactComponent as SvgFluentIcon } from "./icons/fluentIcon.svg";
import { ReactComponent as SvgLocation } from "./icons/location.svg";
import { ReactComponent as SvgContactUs } from "./icons/svgIcon/contactus.svg";
import { ReactComponent as SvgMessanger } from "./icons/messenger.svg";
import { ReactComponent as SvgFire } from "./icons/fire_icon1.svg";
import { ReactComponent as SvgHistory } from "./icons/svgIcon/history.svg";
import { ReactComponent as SvgCancelReservationIcon } from "./icons/svgIcon/cancelReservation.svg";
import { ReactComponent as SvgCompanyIcon } from "./icons/svgIcon/building-icon.svg";
import { ReactComponent as SvgForumTagsIcon } from "./icons/svgIcon/forumTag.svg";
import { ReactComponent as SvgForumFlagsIcon } from "./icons/svgIcon/forumFlag.svg";
import { ReactComponent as SvgGeneralSettingIcon } from "./icons/generalSettings.svg";
import { ReactComponent as SvgCameraPlusIcon } from "./icons/svgIcon/cameraPlus.svg";
import { ReactComponent as SvgCoinsIcon } from "./icons/svgIcon/coins.svg";
import { ReactComponent as SvgCustomNotification } from "./icons/customnotification.svg";
import { ReactComponent as SvgSiteLogo } from "./image/siteLogo.svg";
import { ReactComponent as SvgChatLogo } from "./icons/chatIconHeader.svg";
import { ReactComponent as SvgMobileVersions } from "./icons/mobileVersions.svg";

export const FaLogout = SvgLogout;
export const FaMessanger = SvgMessanger;
export const FaContact = SvgContactUs;
export const FaLocation = SvgLocation;
export const FaChart = SvgChart;
export const FaGavel = SvgGavel;
export const FaPlus = SvgFeature;
export const FaMegaphone = SvgMegaphone;
export const FaEllipses = SvgEllipses;
export const FaDesktop = SvgDesktop;
export const FaPhone = SvgPhone;
export const FaTranslate = SvgTranslate;
export const FaUser = SvgUser;
export const FaPlan = SvgPlan;
export const FaCompany = SvgCompany;
export const FaCoupon = SvgCoupon;
export const FaCommission = SvgCommission;
export const FaRole = SvgRole;
export const FaSlides = SvgSlides;
export const FaImport = SvgImport;
export const FaUpArrow = SvgUpArrow;
export const FaDownArrow = SvgDownArrow;
export const FaFilterIcon = SvgFilterIcon;
export const FaVerificationPending = SvgPendingUser;
export const FaVehicleReservationHistory = SvgVehicleReservationHistory;
export const FaEditVehicle = SvgEditVehicle;
export const FaAds = SvgAds;
export const FaInvoice = SvgInvoice;
export const FaLegal = SvgLegal;
export const FaFaq = SvgFaq;
export const FaAdsIcon = SvgAdsIcon;
export const FaBidsIcon = SvgBidsIcon;
export const FaPartnersIcon = SvgPartnersIcon;
export const FaFluentIcon = SvgFluentIcon;
export const FaFireIcon = SvgFire;
export const FaCreditHistoryIcon = SvgHistory;
export const FaCancelReservationIcon = SvgCancelReservationIcon;
export const FaCompanyIcon = SvgCompanyIcon;
export const FaForumTagsIcon = SvgForumTagsIcon;
export const FaForumFlagsIcon = SvgForumFlagsIcon;
export const FaGeneralSettingIcon = SvgGeneralSettingIcon;
export const FaCameraPlusIcon = SvgCameraPlusIcon;
export const FaCoinsIcon = SvgCoinsIcon;
export const FaCustomNotification = SvgCustomNotification;
export const FaSiteLogo = SvgSiteLogo;
export const FaChatLogo = SvgChatLogo;
export const FaMobileVersions = SvgMobileVersions;
