import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import edit from "../../../assets/Images/image/edit.png";
import noImage from "../../../assets/Images/image/noImage.svg";
import Pagination from "../../../assets/SharedComponents/Pagination";
import { APP_URLS } from "../../../api/url";
import API from "../../../api/axios";
import { toast } from "react-toastify";
import moment from "moment";
import * as Constant from "../../../config/constant";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import SearchKey from "../../../assets/SharedComponents/Search/SearchKey";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";
import StaticFilter from "../../../assets/SharedComponents/Filters/StaticFilter";
import Sorting from "../../../assets/SharedComponents/Sorting/Sorting";
import ProductTypeFilter from "../../../assets/SharedComponents/Filters/ProductTypeFilter";
import CountryFilter from "../../../assets/SharedComponents/Filters/CountryFilter";
import { AuctionEventWrapper } from "../../Auction/AuctionEvent/style/auctionEvent";
import { generateQueryString, getProductTypeName } from "../../../core/utils";

const Days = [
  {
    name: "Monday",
    value: 0,
  },
  {
    name: "Tuesday",
    value: 1,
  },
  {
    name: "Wednesday",
    value: 2,
  },
  {
    name: "Thursday",
    value: 3,
  },
  {
    name: "Friday",
    value: 4,
  },
  {
    name: "Saturday",
    value: 5,
  },
  {
    name: "Sunday",
    value: 6,
  },
];

const userTypesList = Object.keys(Constant.auctionUserTypes).map((key) => ({
  name: key,
  value: Constant.auctionUserTypes[key],
}));
const frequenciesList = Object.keys(Constant.frequencies).map((key) => ({
  name: key,
  value: Constant.frequencies[key],
}));

const HotdealEvents = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();
  const [countries, setcountries] = useState(
    () => JSON.parse(localStorage.getItem("countries")) || []
  );
  const [userAccess, _userAccess] = useState(
    () => JSON.parse(localStorage.getItem("permissions")) || []
  );
  const [loaded, _loaded] = useState(false);
  const [country, setCountry] = useState(
    searchParams.get("ae_country_id") !== null
      ? searchParams.get("ae_country_id")
      : ""
  );
  const [product, setProduct] = useState(
    searchParams.get("ae_product_type_id") !== null
      ? searchParams.get("ae_product_type_id")
      : ""
  );
  const [buyer, setBuyer] = useState(
    searchParams.get("ae_display_for") !== null
      ? searchParams.get("ae_display_for")
      : ""
  );
  const [seller, setSeller] = useState(
    searchParams.get("ae_seller_allowed") !== null
      ? searchParams.get("ae_seller_allowed")
      : ""
  );
  const [recurring, setRecurring] = useState(
    searchParams.get("ae_recurring") !== null
      ? searchParams.get("ae_recurring")
      : ""
  );
  const [startDay, _startDay] = useState(
    searchParams.get("ae_start_day") !== null
      ? searchParams.get("ae_start_day")
      : ""
  );
  const [endDay, _endDay] = useState(
    searchParams.get("ae_end_day") !== null
      ? searchParams.get("ae_end_day")
      : ""
  );
  const [filters, _filters] = useState(false);
  const [sort, _sort] = useState(
    searchParams.get("ae_sort") !== null
      ? searchParams.get("ae_sort")
      : "-first_start_event"
  );
  const hotdealAccess = userAccess.find((o) => o.slug === "hotdeal_event");
  const [searchkeyword, _searchkeyword] = useState(
    searchParams.get("ae_keyword") !== null
      ? searchParams.get("ae_keyword")
      : ""
  );
  const [page, setPage] = useState(
    searchParams.get("ae_page") !== null
      ? parseInt(searchParams.get("ae_page"))
      : 1
  );
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  const [countValue, _countValue] = useState(
    searchParams.get("ae_per_page") !== null
      ? searchParams.get("ae_per_page")
      : ""
  );
  const [data, _data] = useState([]);

  useEffect(() => {
    getAuctionEvents();
  }, [
    page,
    searchkeyword,
    sort,
    country,
    product,
    buyer,
    seller,
    recurring,
    countValue,
    startDay,
    endDay,
  ]);

  //country sorting
  const setChangeValue = (item) => {
    setCountry(item);
    _totalPage(0);
    setPage(1);
  };

  //product sorting
  const getproduct = (item) => {
    setProduct(item);
    _totalPage(0);
    setPage(1);
  };

  //buyer sorting
  const getbuyer = (item) => {
    setBuyer(item);
    _totalPage(0);
    setPage(1);
  };

  //start day sorting
  const getStartDay = (item) => {
    _startDay(item);
    _totalPage(0);
    setPage(1);
  };

  //end day sorting
  const getEndDay = (item) => {
    _endDay(item);
    _totalPage(0);
    setPage(1);
  };

  //seller sorting
  const getseller = (item) => {
    setSeller(item);
    _totalPage(0);
    setPage(1);
  };

  //recurring sorting
  const getrecurring = (item) => {
    setRecurring(item);
    _totalPage(0);
    setPage(1);
  };

  const setsorting = (val) => {
    _sort(val);
    if (sort !== val) {
      setPage(1);
      _totalPage(0);
    }
  };

  // hitting api for auction events
  const getAuctionEvents = () => {
    _loaded(true);
    API.get(
      APP_URLS.HOTDEAL_EVENTS +
        `?page=${page}&per_page=${countValue}&keyword=${searchkeyword}&sort=${sort}&filter[country_id]=${country}&filter[product_type_id]=${product}&filter[recurring]=${recurring}&filter[seller_allowed]=${seller}&filter[display_for]=${buyer}&filter[start_day]=${startDay}&filter[end_day]=${endDay}`
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const openSlug = (id) => {
    window.open(`/company-details/${id}`, "_blank");
  };

  const handleSwitch = (id) => {
    const newData = [...data];
    let val = newData.find((o) => o.id === id);
    if (val.active === 1) {
      val.active = 0;
    } else {
      val.active = 1;
    }
    _data(newData);
  };

  const disableAuction = (id, val) => {
    let data = {};
    data["active"] = val;
    API.post(
      APP_URLS.HOTDEAL_EVENTS + `/${id}/update_status?_method=patch`,
      data
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(
            `Auction Event has been ${
              val === 1 ? "enabled" : "disabled"
            } successfully.`,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          getAuctionEvents();
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if (
      (func === _countValue && searchValue !== countValue) ||
      (func === _searchkeyword && searchValue !== searchkeyword)
    ) {
      if (searchValue !== "") {
        _totalPage(0);
        setPage(1);
      }
    }
  };

  //removing all filters
  const clearFilter = () => {
    _filters(true);
    handleSearchChange(_countValue, "");
    handleSearchChange(_searchkeyword, "");
    setCountry("");
    setProduct("");
    setBuyer("");
    setSeller("");
    setRecurring("");
    _startDay("");
    _endDay("");
    _sort("-first_start_event");
    if (
      searchkeyword !== "" ||
      sort !== "-first_start_event" ||
      countValue !== "" ||
      country !== "" ||
      product !== "" ||
      buyer !== "" ||
      seller !== "" ||
      recurring !== "" ||
      startDay !== "" ||
      endDay !== ""
    ) {
      _totalPage(0);
      setPage(1);
    }
    for (var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
    }
  };

  const createParamsObj = () => {
    return {
      ae_seller_allowed: seller,
      ae_country_id: country,
      ae_display_for: buyer,
      ae_product_type_id: product,
      ae_recurring: recurring,
      ae_sort: sort,
      ae_keyword: searchkeyword,
      ae_per_page: countValue,
      ae_start_day: startDay,
      ae_end_day: endDay,
      ae_page: page,
    };
  };

  const getHotdealIdUrl = (itemId) => {
    const paramsObj = createParamsObj();
    const queryString = generateQueryString(paramsObj);
    return `/hotdeals/${itemId}?${queryString}`;
  };

  const getEditHotdealUrl = (itemId) => {
    const paramsObj = createParamsObj();
    const queryString = generateQueryString(paramsObj);
    return `/edit-hotdeal/${itemId}?${queryString}`;
  };
  return (
    <>
      <AuctionEventWrapper>
        <div className="auctionMainContainer">
          <div className="row mt-4">
            <div className="col-md-4 offset-md-4 mb-2">
              <h1>HOTDEAL EVENTS</h1>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mb-2 me-2">
              {hotdealAccess?.permissions.find((o) => o.slug === "add") ? (
                <div className="actionContainer">
                  <NavLink className="btn" to="/create-hotdeal">
                    + Add
                  </NavLink>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="justify-content-end mb-2 d-flex gap-2 mb-2 select-info align-items-center">
              <CountryFilter value={country} handleChange={setChangeValue} />
              <StaticFilter
                placeholder="Start Day"
                data={Days}
                handleChange={(e) => {
                  getStartDay(e.target.value);
                }}
                value={startDay}
              />
              <StaticFilter
                placeholder="End Day"
                data={Days}
                handleChange={(e) => {
                  getEndDay(e.target.value);
                }}
                value={endDay}
              />
              <StaticFilter
                placeholder="Seller"
                data={userTypesList}
                handleChange={(e) => {
                  getseller(e.target.value);
                }}
                value={seller}
              />
              <StaticFilter
                placeholder="Buyer"
                data={userTypesList}
                handleChange={(e) => {
                  getbuyer(e.target.value);
                }}
                value={buyer}
              />
              <ProductTypeFilter
                handleChange={(e) => getproduct(e.target.value)}
                value={product}
              />
              <StaticFilter
                placeholder="Frequency"
                data={frequenciesList}
                handleChange={(e) => {
                  getrecurring(e.target.value);
                }}
                value={recurring}
              />
              <SearchKey
                onSearchClick={(search) =>
                  handleSearchChange(_searchkeyword, search)
                }
                id="searchData"
                filters={filters}
                val={searchkeyword}
                _filters={_filters}
                placeholder="Search by Title"
              />
              <RecordCount
                onSearchClick={(search) =>
                  handleSearchChange(_countValue, search)
                }
                id="pageCount"
                val={countValue}
                filters={filters}
                _filters={_filters}
              />
              <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon
                  className="filter-icon"
                  role="button"
                  onClick={clearFilter}
                />
              </Tooltip>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-2 me-2">
              <span className="text-start">
                Total Records : <b>{total}</b>
              </span>
            </div>
          </div>
          <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th>ID</th>
                  <th className="w-150 d-flex time-start align-items-center">
                    <div>Hotdeal Title</div>
                    <Sorting
                      sortType="title->en"
                      sort={sort}
                      handleSort={setsorting}
                    />
                  </th>
                  <th className="time-start">
                    Insert Ads Start
                    <Sorting
                      sortType="start_record_before_event"
                      sort={sort}
                      handleSort={setsorting}
                    />
                    <br />
                    End Before Event
                    <Sorting
                      sortType="end_record_before_event"
                      sort={sort}
                      handleSort={setsorting}
                    />
                    <br />
                    <span className="fieldTitle">(Minutes)</span>
                  </th>
                  <th>Product Type</th>
                  <th className="time-start">
                    Start Time
                    <Sorting
                      sortType="first_start_event"
                      sort={sort}
                      handleSort={setsorting}
                    />
                    <br />
                    End Time
                    <Sorting
                      sortType="first_end_event"
                      sort={sort}
                      handleSort={setsorting}
                    />
                  </th>
                  <th>Country</th>
                  <th>Seller</th>
                  <th>Buyer</th>
                  <th>Credit required</th>
                  <th>Recurring</th>
                  <th>Exclusive Seller</th>
                  {hotdealAccess?.permissions.find(
                    (o) => o.slug === "delete"
                  ) ||
                  hotdealAccess?.permissions.find(
                    (o) => o.slug === "update"
                  ) ? (
                    <th>Action</th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              {loaded ? (
                <div className="d-flex justify-content-center three-dot">
                  <ThreeDots
                    height="150"
                    width="150"
                    radius="9"
                    color="#637df4"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <tbody>
                  {data?.length > 0 ? (
                    data.map((item) => {
                      const flag = countries.find(
                        (o) => o.id === item.country_id
                      );
                      const hotdealUrl = getHotdealIdUrl(item.id);
                      const editHotdealUrl = getEditHotdealUrl(item.id);
                      return (
                        <tr className="table-row" key={item.id}>
                          <td>{item.id}</td>
                          <td className="w-150">
                            <NavLink
                              to={hotdealUrl}
                              className="link"
                              title={item.title["en"]}
                            >
                              {item.title["en"]}
                            </NavLink>
                          </td>
                          <td className="time-start">
                            Start : {item.start_record_before_event}
                            <br />
                            End : {item.end_record_before_event}
                          </td>
                          <td>
                            {item?.product_category_type &&
                            item?.product_category_type?.product_type_id
                              ? getProductTypeName(
                                  item.product_category_type.product_type_id
                                )
                              : "-"}
                          </td>
                          <td className="time-start">
                            <div>
                              <span className="d-block">
                                {moment(item.first_start_event)
                                  .tz("Europe/Amsterdam")
                                  .format("DD-MM-YYYY HH:mm")}
                              </span>
                              <span className="fieldTitle">
                                ({item.start_day})
                              </span>
                            </div>
                            <div>
                              <span className="d-block">
                                {moment(item.first_end_event)
                                  .tz("Europe/Amsterdam")
                                  .format("DD-MM-YYYY HH:mm")}
                              </span>
                              <span className="fieldTitle">
                                ({item.end_day})
                              </span>
                            </div>
                          </td>
                          <td>
                            <img
                              src={flag.flag_thumbnail}
                              alt=""
                              className="countryflag"
                              title={flag.fullname}
                            />
                          </td>
                          <td>
                            {Constant.TYPE_OF_PROFILE[item.seller_allowed]}
                          </td>
                          <td>{Constant.TYPE_OF_PROFILE[item.display_for]}</td>
                          <td>{item.credit}</td>
                          <td>{Constant.RECURRING[item.recurring]}</td>
                          <td>
                            {item?.hotdeal_event_sellers?.length > 0
                              ? item.hotdeal_event_sellers.map(
                                  (seller, index) => (
                                    <React.Fragment key={seller.id}>
                                      <span
                                        className="link"
                                        onClick={() =>
                                          openSlug(seller.subsidiary.id)
                                        }
                                      >
                                        {seller.subsidiary.name}
                                      </span>
                                      {index !==
                                        item.hotdeal_event_sellers.length - 1 &&
                                        ", "}
                                      <br />
                                    </React.Fragment>
                                  )
                                )
                              : "-"}
                          </td>
                          {hotdealAccess?.permissions.find(
                            (o) => o.slug === "delete"
                          ) ||
                          hotdealAccess?.permissions.find(
                            (o) => o.slug === "update"
                          ) ? (
                            <td className="action">
                              {hotdealAccess?.permissions.find(
                                (o) => o.slug === "delete"
                              ) ? (
                                <label className="form-check form-switch float-right">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={item.active}
                                    onChange={() => {
                                      handleSwitch(item.id);
                                      disableAuction(item.id, item.active);
                                    }}
                                    title={item.active ? "Disable" : "Enable"}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              ) : (
                                ""
                              )}
                              {hotdealAccess?.permissions.find(
                                (o) => o.slug === "update"
                              ) ? (
                                <button className="ms-2">
                                  <NavLink to={editHotdealUrl}>
                                    <img src={edit} alt="f" />
                                  </NavLink>
                                </button>
                              ) : (
                                ""
                              )}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <NoRecord />
                  )}
                </tbody>
              )}
            </table>
            <div></div>
          </div>
          {totalPage > 1 ? (
            <Pagination
              totalPages={totalPage}
              page={page}
              key={totalPage}
              onPageClick={(page) => setPage(page + 1)}
            />
          ) : (
            ""
          )}
        </div>
      </AuctionEventWrapper>
    </>
  );
};

export default HotdealEvents;
