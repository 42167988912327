import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MobileVersionsWrapper } from "../style/mobileVersion";
import { Switch } from "@mui/material";
import { OSTYPE_INVERSE, versionRegex } from "../../../config/constant";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { APIError } from "../../../core/utils";
import { confirmAlert } from "react-confirm-alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxWidth: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function AddVersion({ OSType, getVersions }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, _loading] = React.useState(false);
  const [version, _version] = React.useState({
    OS: OSType,
    version: "",
    forceUpdate: false,
  });

  React.useEffect(() => {
    if (open) {
      _version({
        OS: OSType,
        version: "",
        forceUpdate: false,
      });
    }
  }, [open]);

  const handleChange = (e) => {
    _version({
      ...version,
      [e.target.name]:
        e.target.name === "forceUpdate" ? e.target.checked : e.target.value,
    });
  };

  const handleSubmit = () => {
    if (loading) return;

    if (!version.version) {
      toast.error("Please enter a valid version number.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (!versionRegex.test(version.version)) {
      toast.error("Please enter a valid version number.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (version.forceUpdate) {
      confirmAlert({
        title: "Confirm Update",
        message: `Are you sure you want to enable force update for version ${version.version}? This will require all users to update immediately.`,
        closeOnEscape: true,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              handleSave();
            },
          },
          {
            label: "No",
          },
        ],
        closeOnClickOutside: true,
        overlayClassName: "overlay-custom-class-name",
      });
      return;
    }
    handleSave();
  };

  const handleSave = () => {
    _loading(true);
    API.post(APP_URLS.MOBILE_VERSIONS, {
      version_number: version.version,
      os_type: version.OS,
      force_update: Number(version.forceUpdate),
    })
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(
            `Version ${version.version} has been successfully added.`,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          _loading(false);
          getVersions();
          handleClose();
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loading(false);
        }
      })
      .catch(function (error) {
        _loading(false);
        let error_message = APIError(error);
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <button className="btn" onClick={handleOpen}>
        + Add Version
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 20 }}
      >
        <Box sx={style}>
          <MobileVersionsWrapper>
            <h2 className="header">Add Version</h2>
            <div class="form-group">
              <label for="OS">OS Type</label>
              <input
                type="text"
                id="OS"
                name="OS"
                placeholder="Enter OS"
                value={OSTYPE_INVERSE[version.OS]}
                onChange={handleChange}
                disabled
              />
            </div>
            <div class="form-group">
              <label for="version">Version</label>
              <input
                type="text"
                id="version"
                name="version"
                value={version.version}
                placeholder="Enter version"
                onChange={handleChange}
              />
            </div>
            <div class="form-group">
              <label for="title">Force Update</label>
              <Switch
                checked={version.forceUpdate}
                onChange={handleChange}
                name="forceUpdate"
              />
            </div>
            <div className="buttons">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={loading}
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={loading}
              >
                Add
              </button>
            </div>
          </MobileVersionsWrapper>
        </Box>
      </Modal>
    </>
  );
}
