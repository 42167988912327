import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { RoleListWrapper } from "./style/role";
import API from "../../api/axios";
import { APP_URLS } from "../../api/url";
import { toast } from "react-toastify";
import NoRecord from "../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../assets/SharedComponents/Pagination";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import UserFilter from "../../assets/SharedComponents/Filters/UserFilter";
import RecordCount from "../../assets/SharedComponents/RecordCount/RecordCount";
import {
  chatLimits,
  FRONT_OFFICE_USER_VERIFICATION_STATUS,
} from "../../config/constant";
import StaticFilter from "../../assets/SharedComponents/Filters/StaticFilter";
import moment from "moment";
import MessagesModal from "./components/MessagesModal";

const verificationStatusList = [
  {
    name: "Not Verified",
    value: 0,
  },
  {
    name: "Verified",
    value: 1,
  },
];

const VerificationStatus = {
  0: "Not Verified",
  1: "Applied",
  2: "Verified",
};

const limitsList = Object.keys(chatLimits).map((key) => ({
  name: chatLimits[key],
  value: key,
}));

const ChatValidator = () => {
  const [data, _data] = useState([]);

  const [page, setPage] = useState(1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  const [countValue, _countValue] = useState("");

  const [userID, _userID] = useState("");
  const [profileStatus, _profileStatus] = useState("");
  const [chatLimitFilter, _chatLimitFilter] = useState("");

  const [filters, _filters] = useState(false);
  const [loaded, _loaded] = useState(false);

  useEffect(() => {
    getChatLimits();
  }, [page, countValue, profileStatus, chatLimitFilter, userID]);

  const getChatLimits = () => {
    _loaded(true);
    API.get(`${APP_URLS.CHAT_VALIDATOR}${getUrl()}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _total(resp.data.total);
          _totalPage(resp.data.last_page);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data?.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getUrl = () => {
    return `?page=${page}&per_page=${countValue}&filter[profile_verification_status]=${profileStatus}&filter[limit_type]=${chatLimitFilter}&filter[user_id]=${userID}`;
  };

  const handleSearchChange = (value) => {
    _countValue(value);
    if (value !== countValue) {
      _totalPage(0);
      setPage(1);
    }
  };

  const setValue = (setUserID, item) => {
    setUserID(item);
    _totalPage(0);
    setPage(1);
  };

  const clearFilter = () => {
    _filters(true);
    _userID("");
    _profileStatus("");
    _chatLimitFilter("");
    handleSearchChange("");
    if (
      countValue !== "" ||
      userID !== "" ||
      profileStatus !== "" ||
      chatLimitFilter !== ""
    ) {
      _totalPage(0);
      setPage(1);
    }
  };

  const generateName = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  return (
    <RoleListWrapper>
      <div className="auctionMainContainer">
        <div className="row mt-4">
          <div className="col-md-4 offset-md-4">
            <h1 className="text-center">Chat Burst Activity Dashboard</h1>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between add-info">
          <div className="d-flex justify-content-end mb-2 data-button"></div>
          <div className="justify-content-end mb-2 d-flex gap-2 mb-2 flex-wrap align-items-center">
            <UserFilter
              _filters={_filters}
              filters={filters}
              setUser={(val) => setValue(_userID, val)}
              user={userID}
              searchByPseudoName={true}
            />
            <StaticFilter
              placeholder="Limit Type"
              data={limitsList}
              handleChange={(e) => {
                setPage(1);
                _totalPage(0);
                _chatLimitFilter(e.target.value);
              }}
              value={chatLimitFilter}
            />
            <StaticFilter
              placeholder="Verification Status"
              data={verificationStatusList}
              handleChange={(e) => {
                setPage(1);
                _totalPage(0);
                _profileStatus(e.target.value);
              }}
              value={profileStatus}
            />
            <RecordCount
              onSearchClick={(search) => handleSearchChange(search)}
              id="pageCount"
              val={countValue}
              filters={filters}
              _filters={_filters}
            />
            <Tooltip title="Clear Filter" placement="bottom">
              <FaFilterIcon
                className="filter-icon"
                role="button"
                onClick={clearFilter}
              />
            </Tooltip>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between info-wrap">
          <div className="justify-content-end mt-1 me-2">
            <span className="text-start">
              Total Records : <b>{total}</b>
            </span>
          </div>
        </div>
        <div className="tableContainer">
          <table className="auctionTable">
            <thead className="tableHead">
              <tr className="table-row">
                <th>User</th>
                <th>Profile Verified</th>
                <th>Attempts </th>
                <th>Limit </th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Messages</th>
              </tr>
            </thead>
            {loaded ? (
              <div className="d-flex justify-content-center three-dot">
                <ThreeDots
                  height="150"
                  width="150"
                  radius="9"
                  color="#637df4"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <tbody>
                {data.length > 0 ? (
                  data.map((limits) => {
                    const { sender_user } = limits;
                    return (
                      <>
                        <tr className="table-row" key={`message-${limits.id}`}>
                          <td>
                            <NavLink
                              to={`/detail-member/${sender_user?.id}`}
                              className="profileLink"
                            >
                              {generateName(sender_user?.name ?? "")}
                            </NavLink>
                          </td>
                          <td>
                            <span
                              className={
                                {
                                  2: "active-status",
                                  1: "progress-status",
                                  0: "end-status",
                                }[sender_user.profile_verification_status]
                              }
                            >
                              {
                                VerificationStatus[
                                  sender_user.profile_verification_status
                                ]
                              }
                            </span>
                          </td>
                          <td>{limits.attempts}</td>
                          <td>{chatLimits[limits.limit_type]}</td>
                          <td>
                            {moment(new Date(limits.start_time))
                              .tz("Europe/Amsterdam")
                              .format("DD-MM-YYYY HH:mm")}
                          </td>
                          <td>
                            {moment(new Date(limits.end_time))
                              .tz("Europe/Amsterdam")
                              .format("DD-MM-YYYY HH:mm")}
                          </td>
                          <td>
                            <MessagesModal id={limits.id} />
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <NoRecord />
                )}
              </tbody>
            )}
          </table>
        </div>
        <div>
          {totalPage > 1 ? (
            <Pagination
              totalPages={totalPage}
              key={totalPage}
              page={page}
              onPageClick={(page) => setPage(page + 1)}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </RoleListWrapper>
  );
};

export default ChatValidator;
