import React, { useEffect, useState } from "react";
import back from "../../../assets/Images/icons/svgIcon/back-arrow.svg";
import arrowUp from "../../../assets/Images/icons/faChevronUp.svg";
import Pagination from "../../../assets/SharedComponents/Pagination";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import moment from "moment";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import SearchKey from "../../../assets/SharedComponents/Search/SearchKey";
import { ThreeDots } from "react-loader-spinner";
import { Button, Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../assets/Images/icon";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";
import download from "downloadjs";
import Sorting from "../../../assets/SharedComponents/Sorting/Sorting";
import { AuctionAdsWrapper } from "../../Auction/AuctionAds/style/auctionAds";
import { downloadFileError, getHotdealUrl } from "../../../core/utils";
import HistoryPopup from "./HistoryPopup";
import EditCreditPricePopup from "./EditCreditPricePopup";
import ReasonsPopup from "./ReasonsPopup";
import AdVisitorModal from "../../../assets/SharedComponents/AdVisitorModal/adVisitorModal";
import { userTypeName, visitorsMethodsTypes } from "../../../config/constant";
import ExtractReport from "../../../assets/SharedComponents/ExtractReport/ExtractReport";

const HotdealAds = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();
  const [trashed, _trashed] = useState(
    searchParams.get("ad_trashed") !== null
      ? searchParams.get("ad_trashed") === "false"
        ? false
        : true
      : true
  );
  const [searchkeyword, _searchkeyword] = useState(
    searchParams.get("ad_keyword") !== null
      ? searchParams.get("ad_keyword")
      : ""
  );
  const [countValue, _countValue] = useState(
    searchParams.get("ad_per_page") !== null
      ? searchParams.get("ad_per_page")
      : ""
  );
  const [userAccess, _userAccess] = useState(
    JSON.parse(localStorage.getItem("permissions"))
  );
  const [title, _title] = useState("");
  const [count, _count] = useState("");
  const [start, _start] = useState("");
  const [startUTC, _startUTC] = useState("");
  const [end, _end] = useState("");
  const hotdealAccess = userAccess.find((o) => o.slug === "hotdeal_event");
  const [page, setPage] = useState(
    searchParams.get("ad_page") !== null
      ? parseInt(searchParams.get("ad_page"))
      : 1
  );
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  const [data, _data] = useState([]);
  const [checked, _checked] = useState(false);
  const [action, _action] = useState(false);
  const [filters, _filters] = useState(false);
  const [dataDeleted, _dataDeleted] = useState(false);
  const [loaded, _loaded] = useState(false);
  const [sort, _sort] = useState("");
  const [popup, _popup] = useState(false);
  const [editPopup, _editPopup] = useState(false);
  const { hotdealId } = useParams();
  const [historyId, setHistoryId] = useState();
  const [editdata, _editdata] = useState();
  const [eventTimeRecord, _eventTimeRecord] = useState();
  const [creditEdited, _creditEdited] = useState(false);
  const [reasonsPopup, _reasonsPopup] = useState(false);
  const navigate = useNavigate();

  const canUpdate = hotdealAccess?.permissions.find((o) => o.slug === "update");

  useEffect(() => {
    //fetching hotdeals ads listings
    const getHotdealEvents = async () => {
      _loaded(true);
      try {
        const res = await API.get(
          `${
            APP_URLS.HOTDEAL_ADS
          }/${hotdealId}?page=${page}&sort=${sort}&per_page=${countValue}&keyword=${searchkeyword}&with_trashed=${
            trashed ? 1 : 0
          }`
        );
        const { data: resp } = res;
        if (resp.success) {
          const updatedData = resp.data.data.map((element) => ({
            ...element,
            checked: false,
          }));
          _data(updatedData);
          _action(false);
          _checked(false);
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _count(resp.data.total);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        const resp = error.response;
        let errorMessage = resp?.data?.message || "An error occurred";
        if (resp?.data?.errors) {
          errorMessage = Object.values(resp.data.errors).flat().join(", ");
        } else if (resp?.data?.data?.error) {
          errorMessage = resp.data.data.error;
        } else if (resp?.data?.error) {
          errorMessage = resp.data.error;
        }
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } finally {
        _loaded(false);
      }
    };

    getHotdealEvents();
    _dataDeleted(false);
  }, [
    page,
    dataDeleted,
    searchkeyword,
    countValue,
    trashed,
    sort,
    creditEdited,
  ]);

  useEffect(() => {
    //fetching hotdeal ads details for heading(start time, end time and heading title)
    const getHotdealDetails = async () => {
      try {
        const res = await API.get(
          `${APP_URLS.HOTDEAL_ADS_DETAIL}/${hotdealId}`
        );
        const { data: resp } = res;
        if (resp.success) {
          const titleKey = Object.keys(resp.data.item.hotdeal_event.title)[0];
          _title(resp.data.item.hotdeal_event.title[titleKey]);
          _start(
            moment(resp.data.item.start)
              .tz("Europe/Amsterdam")
              .format("DD-MM-YYYY HH:mm")
          );
          _startUTC(resp.data.item.start);
          _end(
            moment(resp.data.item.end)
              .tz("Europe/Amsterdam")
              .format("DD-MM-YYYY HH:mm")
          );
          _eventTimeRecord(resp.data.item);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        const resp = error.response;
        let errorMessage = resp?.data?.message || "An error occurred";
        if (resp?.data?.errors) {
          errorMessage = Object.values(resp.data.errors).flat().join(", ");
        } else if (resp?.data?.data?.error) {
          errorMessage = resp.data.data.error;
        } else if (resp?.data?.error) {
          errorMessage = resp.data.error;
        }
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };
    getHotdealDetails();
  }, []);

  // Check if the current date and time is after the registration end time and before the event start time
  function isInTimePeriod(registrationEndStr, eventStartStr) {
    // Convert the given dates and times to moment objects in Amsterdam time zone
    const registrationEnd = moment.tz(registrationEndStr, "Europe/Amsterdam");
    const eventStart = moment.tz(eventStartStr, "Europe/Amsterdam");

    // Get the current date and time in Amsterdam time zone
    const currentDatetime = moment.tz("Europe/Amsterdam");

    return (
      currentDatetime.isAfter(registrationEnd) &&
      currentDatetime.isBefore(eventStart)
    );
  }

  const handleSelect = (id) => {
    let newdata = [...data];
    let val = newdata.find((o) => o.id === id);
    if (val !== undefined) {
      val.checked = !val.checked;
    }
    _data(newdata);
  };

  const selectAll = (val) => {
    _checked(!val);
    let newdata = [...data];
    newdata.forEach((element) => {
      if (element.deleted_at === null) {
        element.checked = !val ? true : false;
      }
    });
    _data(newdata);
  };

  const handleDelete = () => {
    const selectedIds = data
      .filter((element) => element.checked)
      .map((element) => element.id);

    if (selectedIds.length > 0) {
      _reasonsPopup(true);
    } else {
      toast.error("If you want to delete, select some ads first", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSearchChange = (updateFunc, searchValue) => {
    updateFunc(searchValue);

    const isCountValue = updateFunc === _countValue;
    const isSearchKeyword = updateFunc === _searchkeyword;
    const currentValue = isCountValue
      ? countValue
      : isSearchKeyword
      ? searchkeyword
      : null;

    if ((isCountValue || isSearchKeyword) && searchValue !== currentValue) {
      if (searchValue !== "") {
        const paramKey = isCountValue
          ? "per_page"
          : isSearchKeyword
          ? "keyword"
          : null;

        if (paramKey) {
          searchParams.set(paramKey, searchValue);
          setSearchParams(searchParams);
          _totalPage(0);
          setPage(1);
        }
      }
    }
  };

  const clearFilter = () => {
    _filters(true);
    handleSearchChange(_countValue, "");
    handleSearchChange(_searchkeyword, "");
    _trashed(true);

    if (searchkeyword !== "" || countValue !== "" || trashed !== true) {
      _totalPage(0);
      setPage(1);
    }

    const paramsToDelete = ["ad_keyword", "ad_per_page", "ad_trashed"];
    paramsToDelete.forEach((param) => {
      if (searchParams.has(param)) {
        searchParams.delete(param);
      }
    });
    setSearchParams(searchParams);
  };

  const handleSort = (sortType) => {
    _sort(sortType);
    setPage(1);
    _totalPage(0);
  };

  //handles popup for history
  const handleHistoryPopup = (id) => {
    _popup(true);
    setHistoryId(id);
  };

  //handles initial data for edit credit and cartobike_price
  const handleEdit = (adId, ctbPrice, credit, discountedPrice, actualPrice) => {
    _editdata({ adId, ctbPrice, credit, discountedPrice, actualPrice });
    _editPopup(true);
  };
  //filter params
  function getRestUrl() {
    let urlParams = {};
    if (searchkeyword) urlParams["keyword"] = searchkeyword;
    if (countValue) urlParams["per_page"] = countValue;
    if (sort) urlParams["sort"] = sort;
    if (page > 1) urlParams["page"] = page;
    urlParams["with_trashed"] = trashed === true ? 1 : 0;
    const restUrl = new URLSearchParams(urlParams);
    return restUrl;
  }

  const handleExtractReport = () => {
    const apiurl = getRestUrl();
    API.get(APP_URLS.HOTDEALS + `/${hotdealId}/download_excel_file?${apiurl}`, {
      responseType: "blob",
    })
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          toast.success("Downloading. Please wait", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const content = res.headers["content-type"];
          const currentDate = moment().format("YYYY-MM-DD HH-mm-ss");
          const fileName = `${currentDate}_hotdeal_ads_report.xlsx`;
          download(resp, fileName, content);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(async function (error) {
        let error_message = await downloadFileError(error);
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const showCheckbox = (ad) => {
    if (canUpdate && ad.deleted_at === null && start) {
      const currentTime = moment().tz("Europe/Amsterdam");

      if (moment(startUTC).tz("Europe/Amsterdam").isAfter(currentTime)) {
        return false;
      }
    }
    return true;
  };

  const handleAdSoldOffline = (event, index, id) => {
    event.preventDefault();
    _data((prevData) => {
      return prevData.map((item, i) =>
        i === index
          ? { ...item, vehicle_sold_offline: event.target.checked ? 1 : 0 }
          : item
      );
    });
    try {
      API.patch(`/admin/hotdeals/ads/${id}/update_offline_sold_status`, {
        vehicle_sold_offline: event.target.checked ? 1 : 0,
      }).then(() => {
        toast.success("Ad status updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <AuctionAdsWrapper>
        {reasonsPopup === true ? (
          <ReasonsPopup
            _popup={_reasonsPopup}
            data={data}
            _dataDeleted={_dataDeleted}
          />
        ) : (
          ""
        )}
        <div className="auctionMainContainer">
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center">
              <img
                src={back}
                className="ms-4 backbtn"
                onClick={() => navigate(getHotdealUrl(searchParams))}
              />
            </div>
            <div className="col-md-4 mb-2">
              <div className="Cradheading">
                <h1>
                  {title} ({count})
                </h1>
                <div className="startEnd d-flex justify-content-between text-xs mt-2">
                  <span>
                    <b>Start:</b> {start}
                  </span>
                  <span>
                    <b>End:</b> {end}
                  </span>
                </div>
              </div>
            </div>
            {hotdealAccess?.permissions.find((o) => o.slug === "delete") ? (
              <div className="col-md-4 d-flex justify-content-end align-items-center mb-5">
                {action ? (
                  <button
                    className="completeBtn me-4"
                    onClick={() => {
                      _action(false);
                      selectAll(true);
                    }}
                  >
                    Done
                  </button>
                ) : (
                  ""
                )}
                <div className="actionAuction">
                  <div className={(action ? "btnActive" : "") + " actionBtns"}>
                    <button
                      className="actionBtn"
                      onClick={() => {
                        _action(!action);
                        selectAll(true);
                      }}
                    >
                      Action
                      <img
                        src={arrowUp}
                        alt=""
                        className={(action ? "close" : "open") + " ms-2"}
                      />
                    </button>
                  </div>
                  <div className="DelAction">
                    {action ? (
                      <button
                        className={(action ? "" : "") + " actionBtn"}
                        onClick={handleDelete}
                      >
                        Delete
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex justify-content-end my-2 align-items-center gap-2">
            <SearchKey
              onSearchClick={(search) =>
                handleSearchChange(_searchkeyword, search)
              }
              id="searchData"
              val={searchkeyword}
              filters={filters}
              _filters={_filters}
              placeholder="Search by Title"
            />
            <RecordCount
              onSearchClick={(search) =>
                handleSearchChange(_countValue, search)
              }
              id="pageCount"
              val={countValue}
              filters={filters}
              _filters={_filters}
            />
            <ExtractReport
              downloadFunction={handleExtractReport}
              styles="extract-report"
              text=" Extract report"
            />
            <Tooltip title="Clear Filter" placement="bottom">
              <FaFilterIcon
                className="filter-icon"
                role="button"
                onClick={clearFilter}
              />
            </Tooltip>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="mt-1">
              <span className="text-start">
                Total Records : <b>{total}</b>
              </span>
            </div>
            <div className="mt-1">
              {action ? (
                <>
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => selectAll(checked)}
                  />
                  <span className="ms-2 me-3">Select All</span>
                </>
              ) : (
                ""
              )}
              <input
                type="checkbox"
                checked={!trashed}
                onChange={() => {
                  _totalPage(0);
                  setPage(1);
                  _trashed(!trashed);
                }}
              />
              <span className="ms-2">Hide Deleted Data</span>
            </div>
          </div>

          <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  {hotdealAccess?.permissions.find(
                    (o) => o.slug === "delete"
                  ) ? (
                    action ? (
                      <th className="deleteAction"></th>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <th className="time-start">Title Ads</th>
                  <th className="time-start">Brand</th>
                  <th className="time-start">Model</th>
                  <th className="time-start">
                    Actual price
                    <br />
                    VAT %
                  </th>
                  <th className="time-start">
                    Discount price
                    <br />
                    Discount price incl. VAT
                  </th>
                  <th className="time-start">
                    <p className="fieldTitle">Seller Details</p>
                    Creator Name <span className="fieldTitle">(Id)</span>
                    <br />
                    {`${userTypeName.DEALER} Name `}
                    <span className="fieldTitle">(Id)</span>
                  </th>
                  <th className="time-start">
                    Seller type
                    <br />
                    Country
                  </th>
                  {/* <th className="time-start">Seller Name Type</th> */}
                  <th className="time-start">Buyer Name Type</th>
                  <th className="time-start">Credit</th>
                  <th className="time-start">Id Ad</th>
                  <th className="time-start">
                    <div className="d-flex flex-column align-items-center">
                      <span>
                        Created At
                        <Sorting
                          sortType="created_at"
                          sort={sort}
                          handleSort={handleSort}
                        />
                      </span>
                      <span>
                        Updated At
                        <Sorting
                          sortType="updated_at"
                          sort={sort}
                          handleSort={handleSort}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="time-start">
                    <div className="d-flex flex-column align-items-center">
                      <span>
                        Deleted At
                        <Sorting
                          sortType="deleted_at"
                          sort={sort}
                          handleSort={handleSort}
                        />
                      </span>
                      <span>Deleted by User</span>
                      <span>Deleted by RUS</span>
                    </div>
                  </th>
                  <th className="time-start">Cartobike price</th>
                  <th className="time-start">Cartobike total price</th>
                  <th>
                    <div className="time-start">
                      <span>
                        Chat Count
                        <Sorting
                          sortType="chat_count"
                          sort={sort}
                          handleSort={handleSort}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="time-start">
                    <div className="d-flex flex-column align-items-center">
                      <span>
                        No of views
                        <Sorting
                          sortType="click"
                          sort={sort}
                          handleSort={handleSort}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="time-start">
                    <div className="d-flex flex-column align-items-center">
                      <span>
                        No of calls
                        <Sorting
                          sortType="call_count"
                          sort={sort}
                          handleSort={handleSort}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="time-start">Mark Sold Offline</th>
                  <th className="time-start">Access</th>
                </tr>
              </thead>
              {loaded ? (
                <div className="d-flex justify-content-center three-dot">
                  <ThreeDots
                    height="150"
                    width="150"
                    radius="9"
                    color="#637df4"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => {
                      let deletedByUser = "";
                      let deletedByRUS = "";

                      //for buyer info
                      const getBuyerInfo = (customerType) => {
                        const {
                          first_name = "",
                          last_name = "",
                          id = "",
                        } = item.owner_vehicle[customerType] || {};
                        const buyer = `${first_name ?? ""} ${
                          last_name ?? ""
                        }`.trim();
                        const isPro = customerType.includes("sub");
                        const buyerType = isPro ? "Pro" : "Private";
                        const buyerDetailPageLink = isPro
                          ? `/company-details/${id}`
                          : `/detail-member/${id}`;
                        return { buyer, buyerType, buyerDetailPageLink };
                      };

                      const buyerInfo = item.owner_vehicle.sub_customer
                        ? getBuyerInfo("sub_customer")
                        : item.owner_vehicle.user_customer
                        ? getBuyerInfo("user_customer")
                        : item.owner_vehicle.sub_reserved
                        ? getBuyerInfo("sub_reserved")
                        : getBuyerInfo("user_reserved");

                      const { buyer, buyerType, buyerDetailPageLink } =
                        buyerInfo;

                      //for seller info
                      const getSellerInfo = (sellerType) => {
                        const sellerObj = item.owner_vehicle[sellerType] || {};
                        const {
                          first_name = "",
                          last_name = "",
                          name = "",
                          pseudo = "",
                          id = "",
                          limited_address: { vat_rate = "" } = {},
                        } = sellerObj;

                        let seller = "";
                        if (first_name || last_name) {
                          seller = `${first_name} ${last_name}`.trim();
                        } else if (name) {
                          seller = name;
                        } else {
                          seller = pseudo;
                        }

                        const sellerTypeLabel = sellerType.includes("sub")
                          ? "Pro"
                          : "Private";
                        const sellerDetailPageLink = sellerType.includes("sub")
                          ? `/company-details/${id}`
                          : `/detail-member/${id}`;

                        return {
                          seller,
                          sellerTypeLabel,
                          vat_rate,
                          sellerDetailPageLink,
                        };
                      };

                      // Determine seller info based on conditions
                      let sellerInfo = {};
                      if (item.owner_vehicle.current_sub !== null) {
                        sellerInfo = getSellerInfo("current_sub");
                      } else if (item.owner_vehicle.current_user !== null) {
                        sellerInfo = getSellerInfo("current_user");
                      } else {
                        sellerInfo = {
                          seller: "-",
                          sellerTypeLabel: "-",
                          vat_rate: "",
                          sellerDetailPageLink: "",
                        };
                      }
                      const {
                        seller,
                        sellerTypeLabel: seller_type,
                        vat_rate: vat,
                        sellerDetailPageLink: detailPageLink,
                      } = sellerInfo;

                      if (!!item.delete_activity_log) {
                        if (
                          item.delete_activity_log?.first_name ||
                          item.delete_activity_log?.last_name
                        ) {
                          deletedByUser = `${
                            item.delete_activity_log.first_name ?? ""
                          } ${item.delete_activity_log.last_name ?? ""}`;
                        } else if (item.delete_activity_log?.pseudo) {
                          deletedByUser = item.delete_activity_log.pseudo;
                        } else {
                          deletedByUser = "-";
                          deletedByRUS = item.delete_activity_log?.name;
                        }
                      }

                      return (
                        <tr
                          className={
                            (item.deleted_at ? "deletedRow" : "") + " table-row"
                          }
                          key={index}
                        >
                          {action ? (
                            !item.deleted_at ? (
                              <td className="deleteAction">
                                <span className="deleteAuction">
                                  <input
                                    type="checkbox"
                                    className="checkbox-round"
                                    id=""
                                    name="delete"
                                    checked={item.checked}
                                    onChange={(e) => handleSelect(item.id)}
                                  />
                                </span>
                              </td>
                            ) : (
                              <div className="deleteAction"></div>
                            )
                          ) : (
                            ""
                          )}
                          <td className="time-start">
                            {item.owner_vehicle.title
                              ? item.owner_vehicle.title
                              : "-"}
                          </td>
                          <td className="time-start">
                            {item.owner_vehicle.model
                              ? item.owner_vehicle.model.brand_car.name
                              : "-"}
                          </td>
                          <td className="time-start">
                            {item.owner_vehicle.model
                              ? item.owner_vehicle.model.model_name
                              : "-"}
                          </td>
                          <td className="time-start">
                            € {item.public_price}
                            <br />
                            {item.vehicle_deductible_vat && vat
                              ? vat + " %"
                              : "-"}
                          </td>
                          <td className="time-start">
                            € {item.discounted_price}
                            <br />€ {item.total_discounted_price || "-"}
                          </td>
                          {/* <td className="time-start">
                            {seller ? (
                              <a
                                className="link"
                                href={detailPageLink}
                                target="_blank"
                              >
                                {" "}
                                <b>{seller} </b>{" "}
                              </a>
                            ) : (
                              "-"
                            )}
                            <br />
                            <b>{seller_type}</b>
                          </td> */}
                          <td>
                            <>
                              {!!item.created_by ? (
                                <a
                                  className="link"
                                  href={`/detail-member/${item.created_by.id}`}
                                  target="_blank"
                                >
                                  <b>
                                    {`${item.created_by?.first_name} ${item.created_by?.last_name}` ||
                                      item.created_by?.pseudo}
                                  </b>
                                </a>
                              ) : (
                                "-"
                              )}
                              <span className="fieldTitle">
                                ({item.created_by?.id})
                              </span>
                              <br />
                              {!!item.owner_vehicle.current_sub ? (
                                <a
                                  className="link"
                                  href={`/company-details/${item.owner_vehicle.current_sub.id}`}
                                  target="_blank"
                                >
                                  <b>{item.owner_vehicle.current_sub.name}</b>
                                </a>
                              ) : (
                                "-"
                              )}
                              <span className="fieldTitle">
                                ({item.owner_vehicle.current_sub?.id})
                              </span>
                            </>
                          </td>
                          <td className="flag-img time-start">
                            {item.owner_vehicle.current_sub !== undefined &&
                            item.owner_vehicle.current_sub !== null
                              ? userTypeName.DEALER
                              : userTypeName.PRIVATE}
                            <br />

                            {item.owner_vehicle.current_sub !== undefined &&
                            item.owner_vehicle.current_sub !== null ? (
                              <img
                                src={
                                  item.owner_vehicle.current_sub.limited_address
                                    .flag_thumbnail
                                }
                                title={
                                  item.owner_vehicle.current_sub.limited_address
                                    .country
                                }
                                className="countryflag"
                                alt="flag"
                              />
                            ) : item.created_by !== undefined &&
                              item.created_by !== null &&
                              item.created_by.limited_address !== undefined &&
                              item.created_by.limited_address !== null ? (
                              <img
                                src={
                                  item.created_by.limited_address.flag_thumbnail
                                }
                                className="countryflag"
                                alt="flag"
                                title={item.created_by.limited_address.country}
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="time-start">
                            {buyer ? (
                              <a
                                className="link"
                                href={buyerDetailPageLink}
                                target="_blank"
                              >
                                {" "}
                                <b>{buyer} </b>{" "}
                              </a>
                            ) : (
                              "-"
                            )}
                            <br />
                            {buyer ? <b>{buyerType || "-"}</b> : null}
                          </td>
                          <td className="time-start">{item.credit || "-"}</td>
                          <td
                            className="time-start link"
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_FRONTOFFICE_URL_HTTPS}/fr/hot-deal/${item.slug}`,
                                "_blank"
                              )
                            }
                          >
                            {item.id}
                          </td>
                          <td className="time-start">
                            <span className="d-block">
                              {moment(item.created_at)
                                .tz("Europe/Amsterdam")
                                .format("DD-MM-YYYY HH:mm")}
                            </span>
                            <span className="block">
                              {moment(item.updated_at)
                                .tz("Europe/Amsterdam")
                                .format("DD-MM-YYYY HH:mm")}
                            </span>
                          </td>
                          <td className="time-start">
                            <span className="d-block">
                              {item.deleted_at ? (
                                <span>
                                  {moment(item.deleted_at)
                                    .tz("Europe/Amsterdam")
                                    .format("DD-MM-YYYY HH:mm")}
                                </span>
                              ) : (
                                "-"
                              )}
                            </span>
                            <span className="d-block">
                              <b>
                                {!!deletedByUser
                                  ? deletedByUser.charAt(0).toUpperCase() +
                                    deletedByUser.slice(1)
                                  : "-"}
                              </b>
                            </span>
                            <span className="d-block">
                              <b>
                                {deletedByRUS !== "" &&
                                deletedByUser !== undefined
                                  ? deletedByRUS
                                  : "-"}
                              </b>
                            </span>
                          </td>
                          <td className="time-start">
                            {item?.cartobike_price
                              ? `€ ${item.cartobike_price}`
                              : "-"}
                          </td>
                          <td className="time-start">
                            {item?.cartobike_total_price
                              ? `€ ${item.cartobike_total_price}`
                              : "-"}
                          </td>
                          <td>
                            {item.chat_count ? (
                              <AdVisitorModal
                                count={item.chat_count}
                                title={"Chats"}
                                id={item.id}
                                type={visitorsMethodsTypes.chat}
                              />
                            ) : (
                              0
                            )}
                          </td>
                          <td className="time-start">
                            {item.click ? (
                              <AdVisitorModal
                                count={item.click}
                                title={"Views"}
                                id={item.id}
                                type={visitorsMethodsTypes.view}
                              />
                            ) : (
                              0
                            )}
                          </td>
                          <td className="time-start">
                            {item.call_count ? (
                              <AdVisitorModal
                                count={item.call_count}
                                title={"Calls"}
                                id={item.id}
                                type={visitorsMethodsTypes.call}
                              />
                            ) : (
                              0
                            )}
                          </td>
                          <td className="w100">
                            <input
                              key={`${item.id}_${item.vehicle_sold_offline}`}
                              type="checkbox"
                              checked={item.vehicle_sold_offline}
                              disabled={showCheckbox(item)}
                              onChange={(e) =>
                                handleAdSoldOffline(e, index, item.id)
                              }
                            />
                          </td>
                          <td className="w24 time-start">
                            <div className="access-btn">
                              {isInTimePeriod(
                                eventTimeRecord?.end_record,
                                eventTimeRecord?.start
                              ) && !item?.deleted_at ? (
                                <button
                                  className="edit"
                                  onClick={() =>
                                    handleEdit(
                                      item.id,
                                      item?.cartobike_price,
                                      item?.credit,
                                      item?.discounted_price,
                                      item?.public_price
                                    )
                                  }
                                >
                                  Edit
                                </button>
                              ) : null}
                              <button
                                className="accept"
                                onClick={() => handleHistoryPopup(item.id)}
                              >
                                HISTORY
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoRecord />
                  )}
                </tbody>
              )}
            </table>
          </div>
          <div>
            {totalPage > 1 ? (
              <Pagination
                totalPages={totalPage}
                page={page}
                key={totalPage}
                onPageClick={(page) => setPage(page + 1)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <HistoryPopup popup={popup} _popup={_popup} adId={historyId} />
        <EditCreditPricePopup
          popup={editPopup}
          _popup={_editPopup}
          editdata={editdata}
          _creditEdited={_creditEdited}
        />
      </AuctionAdsWrapper>
    </>
  );
};

export default HotdealAds;
