import React, { useEffect, useState } from "react";
import { DashboardWrapper } from "./style/dashboard";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../../assets/SharedComponents/Pagination";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";
import { userTypes } from "../../../config/constant";
import StaticFilter from "../../../assets/SharedComponents/Filters/StaticFilter";
import Sorting from "../../../assets/SharedComponents/Sorting/Sorting";
import CountryFilter from "../../../assets/SharedComponents/Filters/CountryFilter";
import moment from "moment";
import download from "downloadjs";
import ExtractReport from "../../../assets/SharedComponents/ExtractReport/ExtractReport";
import { downloadFileError } from "../../../core/utils";

const typeOfSellers = Object.keys(userTypes).map((key) => ({
  name: key,
  value: userTypes[key],
}));

const getCurrentMonthRange = () => {
  const now = new Date();

  // Get the first day of the current month
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);

  // Get the last day of the current month by getting the first day of the next month and subtracting 1 day
  const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  const lastDay = new Date(nextMonth - 1); // Subtracting 1 from the first day of the next month gives us the last day of the current month

  // Format the dates manually to avoid issues with toISOString()
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return {
    firstDate: formatDate(firstDay), // Correct format: YYYY-MM-DD
    lastDate: formatDate(lastDay), // Correct format: YYYY-MM-DD
  };
};

const Dashboard = () => {
  const subsidiary = localStorage.getItem("subsidiary_id");
  const day = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    .toJSON()
    .slice(0, 10);

  const [dashboardResp, _dashboardResp] = useState([]);
  const [globalResp, _globalResp] = useState({});

  const [loaded, _loaded] = useState(false);
  const [filters, _filters] = useState(false);

  const [appliedFilters, _appliedFilters] = useState({
    count: "",
    seller: userTypes.Dealer,
    to: getCurrentMonthRange()?.lastDate ?? "",
    from: getCurrentMonthRange()?.firstDate ?? "",
    sort: "created_ads",
    country: "",
  });
  const [pagination, _pagination] = useState({
    current: 1,
    total: 1,
  });

  useEffect(() => {
    getCountries();
    getLanguages();
    getproductType();
    getUserPermissions();
    getGroupedCountries();
    localStorage.setItem("Translation", "Languages");
  }, []);

  const getCountries = () => {
    API.get(APP_URLS.COUNTRY_LIST + "?continent=all")
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let languages = resp.data.items;
          localStorage.setItem("countries", JSON.stringify(languages));
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getGroupedCountries = () => {
    API.get(APP_URLS.COUNTRY_LIST + "?continent=all&group=true")
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let languages = resp.data.items;
          localStorage.setItem("groupedCountries", JSON.stringify(languages));
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getLanguages = () => {
    API.get(APP_URLS.ADMIN_LANGUAGES + `?limited_data=true`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let languages = resp.data.items;
          localStorage.setItem("languages", JSON.stringify(languages));
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getproductType = () => {
    API.get(APP_URLS.PRODUCT_TYPES + "?with_category=true")
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          localStorage.setItem("productType", JSON.stringify(resp.data.items));
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data?.email !== undefined) {
          {
            Object.keys(resp.data.data.email).map(
              (error, index) => (error_message = resp.data.data.email[error])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getUserPermissions = () => {
    API.get(APP_URLS.USER_PERMISSION + `/${subsidiary}/permission`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          localStorage.setItem(
            "permissions",
            JSON.stringify(resp.data.items.filter((o) => o.type !== 2))
          );
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data?.email !== undefined) {
          {
            Object.keys(resp.data.data.email).map(
              (error, index) => (error_message = resp.data.data.email[error])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    _loaded(true);
    API.get(`${APP_URLS.DASHBOARD}${getAppliedFilters()}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _dashboardResp(resp?.data?.data?.data ?? []);
          _globalResp(resp?.data?.total ?? {});
          _pagination((prev) => {
            return {
              ...prev,
              current: resp?.data?.data?.current_page,
              total: resp?.data?.data?.last_page,
            };
          });
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        _loaded(false);
      });
  }, [appliedFilters, pagination.current]);

  const getAppliedFilters = () => {
    return `?page=${pagination.current}&per_page=${appliedFilters.count}&sort=${appliedFilters.sort}&filter[country_id]=${appliedFilters.country}&filter[ads_delete_start_date]=${appliedFilters.from}&filter[ads_delete_end_date]=${appliedFilters.to}&filter[user_type]=${appliedFilters.seller}`;
  };

  const clearFilter = () => {
    _appliedFilters({
      count: "",
      sort: "",
      seller: userTypes.Dealer,
      to: getCurrentMonthRange()?.lastDate ?? "",
      from: getCurrentMonthRange()?.firstDate ?? "",
      country: "",
    });
    _pagination({ total: 1, current: 1 });
    _filters(true);
  };

  const filterChange = (key, value) => {
    _pagination(() => ({ total: 1, current: 1 }));
    _appliedFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSort = (sortType) => {
    _appliedFilters((prev) => ({
      ...prev,
      sort: sortType,
    }));
    _pagination(() => ({ total: 1, current: 1 }));
  };

  const handleCountry = (id) => {
    _appliedFilters((prev) => ({
      ...prev,
      country: id,
    }));
    _pagination(() => ({ total: 1, current: 1 }));
  };

  const handleExtractReport = () => {
    API.get(`${APP_URLS.DASHBOARD}/download_excel_file${getAppliedFilters()}`, {
      responseType: "blob",
    })
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          toast.success("Downloading. Please wait", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const content = res.headers["content-type"];
          const currentDate = moment().format("YYYY-MM-DD HH-mm-ss");
          const fileName = `${currentDate}_dashboard_report.xlsx`;
          download(resp, fileName, content);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(async function (error) {
        let error_message = await downloadFileError(error);
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getUrl = (row) => {
    if (appliedFilters.seller === userTypes.Dealer) {
      return `${process.env.REACT_APP_FRONTOFFICE_URL_HTTPS}/fr/pro/${row.slug}`;
    }
    return `${process.env.REACT_APP_FRONTOFFICE_URL_HTTPS}/fr/user/${row.slug}`;
  };

  return (
    <>
      <DashboardWrapper>
        <div className="auctionMainContainer">
          <div className="row mt-4">
            <div className="mb-2">
              <h1>WELCOME TO DASHBOARD</h1>
            </div>
          </div>
          <div className="row">
            <div className="justify-content-end mb-2 d-flex gap-2 mb-2 mt-4 flex-wrap align-items-center">
              <CountryFilter
                value={appliedFilters.country}
                handleChange={handleCountry}
              />
              <input
                type="date"
                className="filterBox date"
                id="start_date"
                placeholder="Start"
                value={appliedFilters.from}
                max={appliedFilters.to ?? day}
                onChange={(e) => filterChange("from", e.target.value)}
              />
              <input
                type="date"
                className="filterBox date"
                id="end_date"
                placeholder="End"
                value={appliedFilters.to}
                max={day}
                onChange={(e) => filterChange("to", e.target.value)}
              />
              <StaticFilter
                placeholder="User Type"
                data={typeOfSellers}
                handleChange={(e) => {
                  filterChange("seller", e.target.value);
                }}
                value={appliedFilters.seller}
              />
              <RecordCount
                onSearchClick={(count) => filterChange("count", count)}
                val={appliedFilters.count}
                id="pageCount"
                filters={filters}
                _filters={_filters}
              />
              <ExtractReport
                downloadFunction={handleExtractReport}
                styles="extract-report ml-2"
                text=" Extract report"
              />
              <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon
                  className="filter-icon"
                  role="button"
                  onClick={clearFilter}
                />
              </Tooltip>
            </div>
          </div>
          <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th>ID</th>
                  <th>
                    {appliedFilters.seller === userTypes.Dealer
                      ? "Dealer name"
                      : "Username"}
                  </th>
                  <th>Country</th>
                  <th>
                    No. of ads created
                    <Sorting
                      sortType="created_ads"
                      sort={appliedFilters.sort}
                      handleSort={handleSort}
                      styles="sortingSvg"
                    />{" "}
                    <br /> No. of ads deleted{" "}
                    <Sorting
                      sortType="deleted_ads"
                      sort={appliedFilters.sort}
                      handleSort={handleSort}
                      styles="sortingSvg"
                    />
                  </th>
                  <th>
                    Total no. of calls
                    <Sorting
                      sortType="call_count"
                      sort={appliedFilters.sort}
                      handleSort={handleSort}
                      styles="sortingSvg"
                    />
                  </th>
                  <th>
                    Total no. of chats
                    <Sorting
                      sortType="chat_count"
                      sort={appliedFilters.sort}
                      handleSort={handleSort}
                      styles="sortingSvg"
                    />
                  </th>
                </tr>
              </thead>
              {loaded ? (
                <div className="d-flex justify-content-center three-dot">
                  <ThreeDots
                    height="150"
                    width="150"
                    radius="9"
                    color="#637df4"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <tbody>
                  {dashboardResp.length > 0 ? (
                    dashboardResp.map((row) => {
                      return (
                        <tr
                          className={
                            (row.deleted_at ? "deletedRow" : "") + " table-row"
                          }
                          key={`dashboard-${row.id}`}
                        >
                          <td>{row.id}</td>
                          <td className="time-start">
                            <span
                              onClick={() => {
                                if (row.deleted_at) return;
                                window.open(getUrl(row), "_blank");
                              }}
                            >
                              <b className={!row.deleted_at && "link"}>
                                {row.name}
                              </b>
                            </span>
                          </td>
                          <td>
                            <img
                              src={row.country_flag}
                              alt=""
                              className="countryflag"
                              title={row.country_name}
                            />
                          </td>
                          <td>
                            {row.created_ads} <br /> {row.deleted_ads}
                          </td>
                          <td>{row.call_count}</td>
                          <td>{row.chat_count}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoRecord />
                  )}
                  <tr className="table-row summary-row">
                    <td>Total :</td>
                    <td></td>
                    <td></td>
                    <td>
                      {globalResp?.total_created_ads ?? 0} <br />{" "}
                      {globalResp?.total_ads_deleted ?? 0}
                    </td>
                    {/* <td> {globalResp?.total_view_count ?? 0}</td> */}
                    <td>{globalResp?.total_call_count ?? 0}</td>
                    <td>{globalResp?.total_chat_count ?? 0}</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <div>
            {pagination.total > 1 ? (
              <Pagination
                totalPages={pagination.total}
                page={pagination.current}
                key={pagination.total}
                onPageClick={(page) =>
                  _pagination((prevValues) => {
                    return { ...prevValues, current: page + 1 };
                  })
                }
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default Dashboard;
