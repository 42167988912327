import { AdsManagementWrapper } from "./style/AdsManagementStyle";
import back from "../../assets/Images/icons/svgIcon/back-arrow.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import API from "../../api/axios";
import { APP_URLS } from "../../api/url";
import { toast } from "react-toastify";
import { APIError, removeUnderscoreAndCapitalize } from "../../core/utils";
import { Switch } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import { confirmAlert } from "react-confirm-alert";
import info from "../../assets/Images/image/info-icon.png";

const AdsManagement = () => {
  const navigate = useNavigate();

  const [adsManagementPageList, _adsManagementPageList] = useState([]);
  const [adsPositioning, _adsPositioning] = useState([]);
  const [loading, _loading] = useState(false);
  const [isSaving, _isSaving] = useState(false);

  useEffect(() => {
    getPages();
  }, []);

  const getPages = () => {
    _loading(true);
    API.get(APP_URLS.ADS_MANAGEMENT)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _loading(false);
          _adsManagementPageList(resp.data.ads_management);
          _adsPositioning(resp.data.third_party_ads_positioning);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loading(false);
        }
      })
      .catch(function (error) {
        _loading(false);
        let error_message = APIError(error);
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleChange = (key, index) => {
    _adsManagementPageList((prevState) => {
      const updatedList = [...prevState];
      updatedList[index][key] = !updatedList[index][key];
      return updatedList;
    });
  };

  const toggleAll = (checked, index) => {
    _adsManagementPageList((prevState) => {
      return prevState.map((item, i) => {
        if (index === i) {
          return {
            ...item,
            ios: checked,
            android: checked,
            web: checked,
          };
        }
        return item;
      });
    });
  };

  const handlePositioningChange = (key, value) => {
    _adsPositioning((prevState) => {
      const updatedList = [...prevState];
      const index = updatedList.findIndex((os) => os.key_name === key);
      updatedList[index].value = value;
      return updatedList;
    });
  };

  const handleSave = () => {
    if (isSaving) return;

    const keys = [
      "third_party_ads_positioning_for_ios",
      "third_party_ads_positioning_for_android",
      "third_party_ads_positioning_for_web",
    ];

    let payload = Object.fromEntries(
      keys.map((key) => [
        key,
        adsPositioning.find((os) => os.key_name === key)?.value,
      ])
    );

    const isInvalid = Object.keys(payload).some((key) => {
      const value = Number(payload[key]);
      if (isNaN(value) || value < 1 || value > 9) {
        toast.error(
          `${removeUnderscoreAndCapitalize(key)} value should be between 1 to 9`
        );
        return true;
      }
      return false;
    });

    if (isInvalid) {
      return;
    }

    const ads_management = {};

    adsManagementPageList.forEach((page) => {
      ads_management[page.page] = {
        ios: page.ios,
        android: page.android,
        web: page.web,
      };
    });

    payload["ads_management"] = ads_management;

    confirmAlert({
      title: "Confirm to save changes",
      message: `Are you sure you want to make these changes?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            _isSaving(true);
            API.patch(APP_URLS.ADS_MANAGEMENT, payload)
              .then((res) => {
                _isSaving(false);
                const resp = res.data;
                if (resp.success === true) {
                  toast.success(
                    "Ads Management settings updated successfully.",
                    {
                      position: toast.POSITION.TOP_RIGHT,
                    }
                  );
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                _isSaving(false);
                let error_message = APIError(error);
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  return (
    <AdsManagementWrapper>
      <div className="row mt-4">
        <div className="col-md-4 d-flex align-items-center">
          <img
            src={back}
            className="ms-4 backbtn"
            alt="back button"
            onClick={() => navigate(-1)}
          />
        </div>
        <div className="col-md-4">
          <div className="heading">
            <h1 className="text-uppercase">Ads Management</h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="d-flex align-items-center justify-content-end">
          <button
            className="btn"
            disabled={loading || isSaving}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-8 offset-lg-2">
          <div className="table-container py-2">
            <table className="table">
              <thead className="mb-3">
                <tr className="header">
                  <th className="pageName">Page</th>
                  <th>{""}</th>
                  <th>IOS</th>
                  <th>Android</th>
                  <th>Web</th>
                </tr>
              </thead>
              {loading ? (
                <div className="d-flex justify-content-center loader">
                  <ThreeDots
                    height="150"
                    width="150"
                    radius="9"
                    color="#637df4"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <>
                  <tbody>
                    {adsManagementPageList &&
                      adsManagementPageList.map((pageList, index) => {
                        return (
                          <tr key={pageList.page}>
                            <td className="pageName">
                              {removeUnderscoreAndCapitalize(pageList.page)}
                            </td>
                            <td className="toggle">
                              <Switch
                                name="selectAll"
                                checked={
                                  pageList.ios &&
                                  pageList.android &&
                                  pageList.web
                                }
                                onChange={(e) =>
                                  toggleAll(e.target.checked, index)
                                }
                              />
                            </td>
                            <td className="permissions">
                              <div className="round-check">
                                <input
                                  type="checkbox"
                                  className={"checkbox-round"}
                                  checked={pageList.ios}
                                  name="ios"
                                  onChange={(e) =>
                                    handleChange(e.target.name, index)
                                  }
                                />
                              </div>
                            </td>
                            <td className="permissions">
                              <div className="round-check">
                                <input
                                  type="checkbox"
                                  className={"checkbox-round"}
                                  checked={pageList.android}
                                  name="android"
                                  onChange={(e) =>
                                    handleChange(e.target.name, index)
                                  }
                                />
                              </div>
                            </td>
                            <td className="permissions">
                              <div className="round-check">
                                <input
                                  type="checkbox"
                                  className={"checkbox-round"}
                                  checked={pageList.web}
                                  name="web"
                                  onChange={(e) =>
                                    handleChange(e.target.name, index)
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td className="pageName">
                        Third party ads frequency
                        <span title="Sets the number of regular ads displayed before a third-party ad appears. For example, if the value is set to a certain number, a third-party ad will be shown after that many ads have played. This helps control the balance between regular and third-party advertisements.">
                          <img src={info} className="ms-1 backbtn" />
                        </span>
                      </td>
                      <td></td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          value={
                            adsPositioning.find(
                              (os) =>
                                os.key_name ===
                                "third_party_ads_positioning_for_ios"
                            )?.value
                          }
                          onChange={(e) =>
                            handlePositioningChange(
                              "third_party_ads_positioning_for_ios",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          value={
                            adsPositioning.find(
                              (os) =>
                                os.key_name ===
                                "third_party_ads_positioning_for_android"
                            )?.value
                          }
                          onChange={(e) =>
                            handlePositioningChange(
                              "third_party_ads_positioning_for_android",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          value={
                            adsPositioning.find(
                              (os) =>
                                os.key_name ===
                                "third_party_ads_positioning_for_web"
                            )?.value
                          }
                          onChange={(e) =>
                            handlePositioningChange(
                              "third_party_ads_positioning_for_web",
                              e.target.value
                            )
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </>
              )}
            </table>
          </div>
        </div>
      </div>
    </AdsManagementWrapper>
  );
};
export default AdsManagement;
