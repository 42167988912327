import React from "react";
import SearchableDropdown from "../SearchableDropdown/SearchableDropdown";

function CountryFilter({ value, handleChange }) {
  const countries = JSON.parse(localStorage.getItem("countries")) ?? [];

  if (!countries || countries.length === 0) {
    return null;
  }

  return (
    <SearchableDropdown
      name="country_id"
      className="filterBox"
      setChangeValue={(val) => handleChange(val)}
      placeholder="Select Country"
      val={value}
      dropdownData={countries}
    />
  );
}

export default CountryFilter;
