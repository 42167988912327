import styled from "styled-components";

export const MobileVersionsWrapper = styled.div`
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  .form-group {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  .form-group label {
    width: 50%;
    font-size: 16px;
    font-weight: 700;
  }
  .form-group input {
    padding: 5px;
  }
  .MuiSwitch-sizeMedium {
    margin-left: -12px;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 30px;
    justify-content: end;
    button {
      min-width: 100px;
    }
  }
`;
