import React, { useCallback, useEffect, useState } from "react";
import { MobileVersionsWrapper } from "./style/mobileVersion";
import { ThreeDots } from "react-loader-spinner";
import NoRecord from "../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../assets/SharedComponents/Pagination";
import { FaFilterIcon } from "../../assets/Images/icon";
import RecordCount from "../../assets/SharedComponents/RecordCount/RecordCount";
import { Switch, Tab, Tabs, Tooltip } from "@mui/material";
import AddVersion from "./addVersion/AddVersion";
import API from "../../api/axios";
import { toast } from "react-toastify";
import { APP_URLS } from "../../api/url";
import { OSTYPE, OSTYPE_INVERSE } from "../../config/constant";
import moment from "moment";
import { APIError } from "../../core/utils";
import { confirmAlert } from "react-confirm-alert";

const MobileVersions = () => {
  const [userAccess, _userAccess] = useState(() => {
    return JSON.parse(localStorage.getItem("permissions")) || [];
  });

  const mobileVersion = userAccess?.find((o) => o.slug === "mobile_verison");

  const mobileVersionAddPermission = mobileVersion?.permissions?.find(
    (o) => o.slug === "add"
  );

  const mobileVersionUpdatePermission = mobileVersion?.permissions?.find(
    (o) => o.slug === "update"
  );

  const [versions, _versions] = useState([]);
  const [versionUpdate, _versionUpdate] = useState(false);

  const [loading, _loading] = useState(true);
  const [currentPage, _currentPage] = useState(1);

  const [filters, _filters] = useState(false);
  const [countValue, _countValue] = useState("");

  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);

  const [tab, setTab] = React.useState(OSTYPE.IOS);

  useEffect(() => {
    getVersions();
  }, [tab, currentPage, countValue]);

  const getVersions = () => {
    _loading(true);
    API.get(
      APP_URLS.MOBILE_VERSIONS +
        `?page=${currentPage}&per_page=${countValue}&filter[os_type]=${tab}`
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _versions(resp.data.data);
          _total(resp.data.total);
          _totalPage(resp.data.last_page);
          _loading(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loading(false);
        }
      })
      .catch(function (error) {
        _loading(false);
        let error_message = APIError(error);
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const clearFilter = () => {
    _filters(true);
    _countValue("");
    _currentPage(1);
  };

  const handleForceUpdate = (version) => {
    if (versionUpdate) return;

    const isEnabling = !version.force_update;
    const actionText = isEnabling ? "enable" : "disable";

    confirmAlert({
      title: "Confirm Update",
      message: `Are you sure you want to ${actionText} force update for version ${
        version.version_number
      }? This will ${
        isEnabling
          ? "require all users to update immediately."
          : "allow users to continue using older versions."
      }`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            _versionUpdate(true);
            API.patch(
              APP_URLS.MOBILE_VERSIONS +
                `/${version.id}?force_update=${Number(isEnabling)}`
            )
              .then((res) => {
                const resp = res.data;
                _versionUpdate(false);
                if (resp.success === true) {
                  toast.success(
                    `Force update has been ${
                      isEnabling ? "enabled" : "disabled"
                    } successfully.`,
                    {
                      position: toast.POSITION.TOP_RIGHT,
                    }
                  );
                  _versions((prevVersions) => {
                    return prevVersions.map((v) =>
                      v.id === version.id
                        ? { ...v, force_update: Number(isEnabling) }
                        : v
                    );
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                _versionUpdate(false);
                let error_message = APIError(error);
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  return (
    <MobileVersionsWrapper>
      <div className="auctionMainContainer">
        <div className="row mt-4">
          <div className="col-md-4 offset-md-4 mb-2">
            <h1>Mobile Versions</h1>
            <Tabs
              value={tab}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="primary tabs example"
              centered
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#607afc",
                },
              }}
            >
              <Tab
                value={OSTYPE.IOS}
                label="IOS"
                sx={{
                  color: tab === OSTYPE.IOS ? "#607afc !important" : "gray",
                  fontWeight: "bold",
                }}
              />
              <Tab
                value={OSTYPE.ANDROID}
                label="Android"
                sx={{
                  color: tab === OSTYPE.ANDROID ? "#607afc !important" : "gray",
                  fontWeight: "bold",
                }}
              />
            </Tabs>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between info-wrap">
          {mobileVersionAddPermission && (
            <div className="justify-content-end mb-2 me-2">
              <div className="actionContainer">
                <AddVersion OSType={tab} getVersions={getVersions} />
              </div>
            </div>
          )}
          <div className="justify-content-end mb-2 d-flex gap-2 mb-2 select-info align-items-center">
            <RecordCount
              onSearchClick={(search) => _countValue(search)}
              id="pageCount"
              val={countValue}
              filters={filters}
              _filters={_filters}
            />
            <Tooltip title="Clear Filter" placement="bottom">
              <FaFilterIcon
                className="filter-icon"
                role="button"
                onClick={clearFilter}
              />
            </Tooltip>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between info-wrap">
          <div className="justify-content-end mt-2 me-2">
            <span className="text-start">
              Total Records : <b>{total}</b>
            </span>
          </div>
        </div>
        <div className="tableContainer">
          <table className="auctionTable">
            <thead className="tableHead">
              <tr className="table-row">
                <th className="time-start">Version Number</th>
                <th className="time-start">OS Type</th>
                <th className="time-start">Added On</th>
                <th className="time-start">Last Updated</th>
                {mobileVersionUpdatePermission && (
                  <th className="time-start">Force Update</th>
                )}
              </tr>
            </thead>
            {loading ? (
              <div className="d-flex justify-content-center three-dot">
                <ThreeDots
                  height="150"
                  width="150"
                  radius="9"
                  color="#637df4"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <tbody>
                {versions.length > 0 ? (
                  versions.map((version, index) => {
                    return (
                      <tr key={`version-${version.id}`} className="table-row">
                        <td>{version.version_number}</td>
                        <td>{OSTYPE_INVERSE[version.os_type]}</td>
                        <td>
                          {moment(version.created_at)
                            .tz("Europe/Amsterdam")
                            .format("DD-MM-YYYY HH:mm")}
                        </td>
                        <td>
                          {moment(version.updated_at)
                            .tz("Europe/Amsterdam")
                            .format("DD-MM-YYYY HH:mm")}
                        </td>
                        {mobileVersionUpdatePermission && (
                          <td>
                            <Switch
                              checked={version.force_update}
                              onChange={() => handleForceUpdate(version)}
                              name="forceUpdate"
                              disabled={
                                versionUpdate ||
                                currentPage !== 1 ||
                                index !== 0
                              }
                            />
                          </td>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <NoRecord />
                )}
              </tbody>
            )}
          </table>
        </div>
        <div>
          {totalPage > 1 ? (
            <Pagination
              totalPages={totalPage}
              page={currentPage}
              key={totalPage}
              onPageClick={(page) => _currentPage(page + 1)}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </MobileVersionsWrapper>
  );
};

export default MobileVersions;
