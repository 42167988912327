import styled from "styled-components";

export const AdsManagementWrapper = styled.div`
  .table-container {
    height: auto;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
  }
  .table {
    color: #909294;
    width: 100%;
    min-width: 600px;
  }
  th,
  td {
    width: 20%;
    text-align: center;
    border: none;
  }
  .header {
    background: #b6b6b61f;
  }
  .pageName {
    min-width: 300px;
  }
  tbody .pageName {
    text-align: left;
    padding-left: 20px;
  }
  .toggle {
    display: flex;
    justify-content: end;
    width: 100%;
  }
  .loader {
    align-items: center;
    height: 75vh;
    left: 50%;
    position: absolute;
  }
  .form-control {
    max-width: 50%;
    margin: auto;
  }
`;
