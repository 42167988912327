import React, { useEffect, useMemo, useState } from "react";
import { FaDownArrow, FaUpArrow, FaFilterIcon } from "../../assets/Images/icon";
import { BidListWRapper } from "./style/bidList";
import warning from "../../assets/Images/icons/svgIcon/Spam.svg";
import { toast } from "react-toastify";
import API from "../../api/axios";
import { APP_URLS } from "../../api/url";
import moment from "moment";
import * as Constant from "../../config/constant";
import NoRecord from "../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../assets/SharedComponents/Pagination";
import noImage from "../../assets/Images/image/noImage.svg";
import { ThreeDots } from "react-loader-spinner";
import { Button, Tooltip } from "@mui/material";
import SearchKey from "../../assets/SharedComponents/Search/SearchKey";
import arrowUp from "../../assets/Images/icons/faChevronUp.svg";
import { confirmAlert } from "react-confirm-alert";
import RecordCount from "../../assets/SharedComponents/RecordCount/RecordCount";
import SearchableObjDropdownWithImage from "../../assets/SharedComponents/SearchableDropdown/SearchableObjDropdownWithImage";
import { getName } from "../../core/utils/getName/getName";
import { NavLink } from "react-router-dom";
import SearchableObjDropdown from "../../assets/SharedComponents/SearchableDropdown/SearchableObjDropdown";
import { PusherClient } from "../../websocket.js";
import StaticFilter from "../../assets/SharedComponents/Filters/StaticFilter";
import Sorting from "../../assets/SharedComponents/Sorting/Sorting";
import UserFilter from "../../assets/SharedComponents/Filters/UserFilter";
import SubsidiaryFilter from "../../assets/SharedComponents/Filters/SubsidiaryFilter";
import BrandFilter from "../../assets/SharedComponents/Filters/BrandFilter";
import ModelFilter from "../../assets/SharedComponents/Filters/ModelFilter";
import download from "downloadjs";
import ProductTypeFilter from "../../assets/SharedComponents/Filters/ProductTypeFilter.js";
import ExtractReport from "../../assets/SharedComponents/ExtractReport/ExtractReport";
import { downloadFileError } from "../../core/utils.js";

const TrueFalse = {
  True: true,
  False: false,
};

const validateStatus = Object.keys(Constant.adsValidStatus).map((key) => ({
  name: key,
  value: Constant.adsValidStatus[key],
}));
const isTrueOrFalse = Object.keys(TrueFalse).map((key) => ({
  name: key,
  value: TrueFalse[key],
}));
const bidPercentsList = Object.keys(Constant.bidPercents).map((key) => ({
  name: key,
  value: Constant.bidPercents[key],
}));
const BidLists = () => {
  PusherClient.subscribe("bid_auctions");
  const channelAc = PusherClient.channel("bid_auctions");

  const [loaded, _loaded] = useState(false);
  const [totalPage, _totalPage] = useState(0);
  const [bids, _bids] = useState([]);
  const [total, _total] = useState(0);
  const [page, setPage] = useState(1);
  const [userID, _userID] = useState("");
  const [subID, _subID] = useState("");
  const [brand, _brand] = useState("");
  const [model, _model] = useState("");

  const [searchkeyword, _searchkeyword] = useState("");
  const [countValue, _countValue] = useState("");

  const [sort, _sort] = useState("-created_at");

  const [filters, _filters] = useState(false);
  const [action, _action] = useState(false);
  const [checked, _checked] = useState(false);
  const [dataDeleted, _dataDeleted] = useState(false);

  const [variation, _variation] = useState("");
  const [adsInProgress, _adsInProgress] = useState("");
  const [trashed, _trashed] = useState(true);
  const [validated, _validated] = useState("");

  const [bidId, _bidId] = useState("");
  const [bidsvalidated, _bidsvalidated] = useState([]);

  const [userAccess, _userAccess] = useState(
    JSON.parse(localStorage.getItem("permissions"))
  );

  const [productValue, _productValue] = useState("");

  let bidsAccess = userAccess.find((o) => o.slug === "bid_list");

  useEffect(() => {
    getBidsList();
    _dataDeleted(false);
  }, [
    page,
    searchkeyword,
    dataDeleted,
    countValue,
    variation,
    adsInProgress,
    userID,
    subID,
    sort,
    trashed,
    productValue,
    validated,
    brand,
    model,
  ]);

  useEffect(() => {
    if (bidId !== "") {
      validate(bidId);
    }
  }, [bidId]);

  const listenMessages = async () => {
    await channelAc.bind("NewBidApplied", (data) => {
      const bidItem = data?.message;
      if (Object.keys(bidItem).length > 0) {
        _bids((oldBids) => [bidItem, ...oldBids]);
      }
    });
  };

  useEffect(() => {
    listenMessages();
  }, []);

  const getBidsList = () => {
    _loaded(true);
    _bidsvalidated([]);
    API.get(
      APP_URLS.LIST_BIDS +
        `?page=${page}&per_page=${countValue}&keyword=${searchkeyword}&filter[variation]=${variation}&filter[ad_status]=${adsInProgress}&filter[customer_user_id]=${userID}&filter[customer_sub_id]=${subID}&filter[product_type_id]=${productValue}&sort=${sort}&with_trashed=${
          trashed === true ? 1 : 0
        }&filter[validated]=${validated}&filter[brand_id]=${brand}&filter[model_id]=${model}`
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _bids([]);
          _action(false);
          _checked(false);
          resp.data.data.forEach((element) => {
            element.checked = false;
            _bids((old) => {
              return [...old, element];
            });
          });
          _totalPage(resp.data.last_page);
          _loaded(false);
          _total(resp.data.total);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const setsorting = (val) => {
    _sort(val);
    if (sort !== val) {
      setPage(1);
      _totalPage(0);
    }
  };

  const setChangeValue = (func, item) => {
    func(item);
    _totalPage(0);
    setPage(1);
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if (searchValue !== "") {
      _totalPage(0);
      setPage(1);
    }
  };

  const clearFilter = () => {
    _filters(true);
    handleSearchChange(_countValue, "");
    handleSearchChange(_searchkeyword, "");
    _variation("");
    _adsInProgress("");
    _userID("");
    _subID("");
    _brand("");
    _model("");
    _trashed(true);
    _validated("");
    _sort("ad.end_auction");
    _productValue("");
    if (
      searchkeyword !== "" ||
      sort !== "ad.end_auction" ||
      countValue !== "" ||
      trashed !== true ||
      validated !== ""
    ) {
      _totalPage(0);
      setPage(1);
    }
  };

  const handleSelect = (id) => {
    let data = [...bids];
    let val = data.find((o) => o.id === id);
    if (val !== undefined) {
      val.checked = !val.checked;
    }
    _bids(data);
  };

  const selectAll = (val) => {
    _checked(!val);
    let data = [...bids];
    data.forEach((element) => {
      if (element.deleted_at === null) {
        element.checked = !val ? true : false;
      }
    });
    _bids(data);
  };

  const handleDelete = () => {
    let arr = [];
    bids.forEach((element) => {
      if (element.checked === true) {
        arr.push(element.id);
      }
    });
    if (arr.length > 0) {
      let str = "";
      for (let i = 0; i < arr.length; i++) {
        str += `id[${i}]=` + arr[i] + "&";
      }
      confirmAlert({
        title: "Confirm to remove",
        message: `Are you sure you want to remove?`,
        closeOnEscape: true,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              API.delete(APP_URLS.DELETE_BIDS + `?${str}`)
                .then((res) => {
                  const resp = res.data;
                  if (resp.success === true) {
                    _dataDeleted(true);
                    toast.success("Bid has been deleted successfully.", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  } else {
                    toast.error(resp.message, {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  }
                })
                .catch(function (error) {
                  const resp = error.response;
                  let error_message = "";
                  if (resp.data.data !== undefined && resp.data.data !== null) {
                    {
                      Object.keys(resp.data.data).map(
                        (error, index) =>
                          (error_message = resp.data.data[error][0])
                      );
                    }
                  } else if (resp.data.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                  } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error;
                  } else {
                    error_message = resp.data.message;
                  }
                  toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                });
            },
          },
          {
            label: "No",
          },
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        overlayClassName: "overlay-custom-class-name",
      });
    } else {
      toast.error("If you want to delete, select some bids first", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const validate = (index) => {
    confirmAlert({
      title: "Confirm to validate",
      message: `Are you sure you want to validate this bid?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.post(APP_URLS.VALIDATE_BID + `/${index}/validate?_method=PATCH`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  toast.success("Bid has been validated successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  _bidsvalidated((old) => [...old, resp.data.values[0]]);
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map(
                      (error, index) =>
                        (error_message = resp.data.data[error][0])
                    );
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => {
            _bidId("");
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const getValidatedUser = (id) => {
    let data = bidsvalidated.find((o) => o.id === id);
    if (data !== undefined) {
      return {
        validated_by: getName(data.validated_by),
        validated_at: data.validated_at,
      };
    } else {
      return false;
    }
  };

  const handleNavigationforBidder = (bid) => {
    if (bid?.sub_customer) {
      return `/company-details/${bid.sub_customer.id}`;
    }
    if (bid?.user_customer) {
      return `/detail-member/${bid.user_customer.id}`;
    }
  };

  const handleNavigationforSeller = (bid) => {
    if (bid?.ad?.owner_vehicle?.current_sub) {
      return `/company-details/${bid?.ad?.owner_vehicle?.current_sub.id}`;
    }
    if (bid?.ad?.owner_vehicle?.current_user) {
      return `/detail-member/${bid?.ad?.owner_vehicle?.current_user.id}`;
    }
  };

  const handleValidated = (value) => {
    _totalPage(0);
    setPage(1);
    _validated(value);
  };
  const handleBidPercent = (value) => {
    _variation(value);
    _totalPage(0);
    setPage(1);
  };

  const handleExtractReport = () => {
    API.get(
      `${
        APP_URLS.LIST_BIDS
      }/download_bids_report?page=${page}&per_page=${countValue}&keyword=${searchkeyword}&filter[variation]=${variation}&filter[ad_status]=${adsInProgress}&filter[customer_user_id]=${userID}&filter[customer_sub_id]=${subID}&sort=${sort}&with_trashed=${
        trashed === true ? 1 : 0
      }&filter[validated]=${validated}&filter[brand_id]=${brand}&filter[model_id]=${model}`,
      { responseType: "blob" }
    )
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          toast.success("Downloading. Please wait", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const content = res.headers["content-type"];
          const currentDate = moment().format("YYYY-MM-DD HH-mm-ss");
          const fileName = `${currentDate}_bids_report.xlsx`;
          download(resp, fileName, content);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(async function (error) {
        let error_message = await downloadFileError(error);
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <BidListWRapper>
      <div className="auctionMainContainer">
        <div className="row mt-4">
          <div className="col-md-4 offset-md-4">
            <h1>BIDS LIST</h1>
          </div>
          {bidsAccess?.permissions.find((o) => o.slug === "delete") ? (
            <div className="col-md-4 d-flex justify-content-end align-items-center mb-5">
              {action ? (
                <button
                  className="completeBtn me-4"
                  onClick={() => {
                    _action(false);
                    selectAll(true);
                  }}
                >
                  Done
                </button>
              ) : (
                ""
              )}
              <div className="actionAuction">
                <div className={(action ? "btnActive" : "") + " actionBtns"}>
                  <button
                    className="actionBtn"
                    onClick={() => {
                      _action(!action);
                      selectAll(true);
                    }}
                  >
                    Action
                    <img
                      src={arrowUp}
                      alt=""
                      className={(action ? "close" : "open") + " ms-2"}
                    />
                  </button>
                </div>
                <div className="DelAction">
                  {action ? (
                    <button
                      className={(action ? "" : "") + " actionBtn"}
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex align-items-center justify-content-end info-wrap">
          <div className="justify-content-end mb-2 d-flex gap-2 mb-2 select-info flex-wrap align-items-center">
            <ProductTypeFilter
              handleChange={(e) => {
                _productValue(e.target.value);
                _brand("");
                _model("");
                _totalPage(0);
                setPage(1);
              }}
              value={productValue}
            />
            <BrandFilter
              _filters={_filters}
              filters={filters}
              brand={brand}
              setBrand={(val) => setChangeValue(_brand, val)}
              productValue={productValue}
            />
            <ModelFilter
              _filters={_filters}
              filters={filters}
              brandID={brand}
              modelID={model}
              setChangeValue={(val) => setChangeValue(_model, val)}
            />
            <UserFilter
              _filters={_filters}
              filters={filters}
              setUser={(val) => setChangeValue(_userID, val)}
              user={userID}
            />
            <SubsidiaryFilter
              _filters={_filters}
              filters={filters}
              setSub={(val) => setChangeValue(_subID, val)}
              sub={subID}
            />
            <StaticFilter
              placeholder="Prices Above Goal"
              data={bidPercentsList}
              handleChange={(e) => {
                handleBidPercent(e.target.value);
              }}
              value={variation}
            />
            <StaticFilter
              placeholder="Ads in Progress"
              data={isTrueOrFalse}
              handleChange={(e) => {
                _adsInProgress(e.target.value);
                _totalPage(0);
                setPage(1);
              }}
              value={adsInProgress}
            />
            <StaticFilter
              placeholder="Ads Validity"
              data={validateStatus}
              handleChange={(e) => {
                handleValidated(e.target.value);
              }}
              value={validated}
            />
            <SearchKey
              onSearchClick={(search) =>
                handleSearchChange(_searchkeyword, search)
              }
              id="searchData"
              filters={filters}
              _filters={_filters}
              placeholder="Search by Title"
            />
            <RecordCount
              onSearchClick={(search) =>
                handleSearchChange(_countValue, search)
              }
              id="pageCount"
              filters={filters}
              _filters={_filters}
            />
            <ExtractReport
              downloadFunction={handleExtractReport}
              styles="extract-report"
              text=" Extract report"
            />
            <div className="filter">
              <Tooltip title="Clear Filter" placement="bottom" className="ms-2">
                <FaFilterIcon
                  className="filter-icon"
                  role="button"
                  onClick={clearFilter}
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between info-wrap">
          <div className="mt-1">
            <span className="text-start">
              Total Records : <b>{total}</b>
            </span>
          </div>
          <div className="mt-1">
            {action ? (
              <>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => selectAll(checked)}
                />
                <span className="ms-2 me-3">Select All</span>
              </>
            ) : (
              ""
            )}
            <input
              type="checkbox"
              checked={!trashed}
              onChange={() => {
                _totalPage(0);
                setPage(1);
                _trashed(!trashed);
              }}
            />
            <span className="ms-2">Hide Deleted Data</span>
          </div>
        </div>
        <div className="tableContainer">
          <table className="auctionTable">
            <thead className="tableHead">
              <tr className="table-row">
                {bidsAccess?.permissions.find((o) => o.slug === "delete") ? (
                  action ? (
                    <th className="deleteAction"></th>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <th>Ad ID</th>
                <th>Title ads</th>
                <th>Brand</th>
                <th>Model</th>
                <th>
                  Target excl. VAT <br />
                  VAT amount <br />
                  Target incl. VAT{" "}
                </th>
                <th className="time-start d-flex flex-column">
                  <span>
                    Date time
                    <Sorting
                      sortType="created_at"
                      sort={sort}
                      handleSort={setsorting}
                    />
                  </span>
                  <span>
                    Bid
                    <Sorting
                      sortType="amount"
                      sort={sort}
                      handleSort={setsorting}
                    />
                  </span>
                  Variation %
                </th>
                <th>
                  Country <br />
                  Bidder <br />
                  Type
                </th>
                <th>
                  Seller name <br />
                  Type
                </th>
                <th>
                  Status <br /> Auction name
                </th>
                <th className="time-start">
                  Ads in going
                  <Sorting
                    sortType="ad.end_auction"
                    sort={sort}
                    handleSort={setsorting}
                  />
                </th>
                <th>Validate</th>
              </tr>
            </thead>
            {loaded ? (
              <div className="d-flex justify-content-center three-dot">
                <ThreeDots
                  height="150"
                  width="150"
                  radius="9"
                  color="#637df4"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <tbody>
                {bids.length > 0 ? (
                  bids.map((bid, index) => {
                    let name = "";
                    let bidderId = null;
                    let sellerId = null;
                    let flag = "";
                    let sellerName = "";

                    if (
                      bid.sub_customer !== undefined &&
                      bid.sub_customer !== null
                    ) {
                      name = bid.sub_customer.name;
                      flag = bid.sub_customer.limited_address;
                      bidderId = bid.sub_customer.id;
                    } else if (
                      bid.user_customer !== undefined &&
                      bid.user_customer !== null
                    ) {
                      flag = bid.user_customer.limited_address;
                      if (
                        bid.user_customer.first_name ||
                        bid.user_customer.last_name
                      ) {
                        name = `${bid.user_customer.first_name} ${bid.user_customer.last_name}`;
                      } else {
                        name = bid.user_customer.pseudo;
                      }
                      bidderId = bid.user_customer.id;
                    } else {
                      name = "-";
                    }

                    if (bid.ad.owner_vehicle.current_sub !== null) {
                      sellerName = bid.ad.owner_vehicle.current_sub.name;
                      sellerId = bid.ad.owner_vehicle.current_sub_id;
                    } else if (bid.ad.owner_vehicle.current_user !== null) {
                      if (
                        bid.ad.owner_vehicle.current_user.first_name ||
                        bid.ad.owner_vehicle.current_user.last_name
                      ) {
                        sellerName = `${
                          bid.ad.owner_vehicle.current_user.first_name !== null
                            ? bid.ad.owner_vehicle.current_user.first_name
                            : ""
                        } ${
                          bid.ad.owner_vehicle.current_user.last_name !== null
                            ? bid.ad.owner_vehicle.current_user.last_name
                            : ""
                        }`;
                      } else {
                        sellerName = bid.ad.owner_vehicle.current_user.pseudo;
                      }
                      sellerId = bid.ad.owner_vehicle.current_user_id;
                    } else {
                      sellerName = "-";
                    }

                    let amount = "";
                    if (bid.amount !== null) {
                      amount = (bid.amount / bid.ad.public_price) * 100;
                    }
                    return (
                      <tr
                        className={
                          (bid.deleted_at ? "deletedRow" : "") + " table-row"
                        }
                        key={index}
                      >
                        {action ? (
                          !bid.deleted_at ? (
                            <td className="deleteAction">
                              <span className="deleteAuction">
                                <input
                                  type="checkbox"
                                  className="checkbox-round"
                                  id=""
                                  name="delete"
                                  checked={bid.checked}
                                  onChange={(e) => handleSelect(bid.id)}
                                />
                              </span>
                            </td>
                          ) : (
                            <div className="deleteAction"></div>
                          )
                        ) : (
                          ""
                        )}
                        <td className="productDetail">
                          {bid.ad !== null ? bid.ad.id : "-"}
                        </td>
                        <td
                          onClick={() =>
                            bid.deleted_at || bid.ad === null
                              ? ""
                              : window.open(
                                  `${process.env.REACT_APP_FRONTOFFICE_URL_HTTPS}/fr/auction-ads/${bid.ad.slug}`,
                                  "_blank"
                                )
                          }
                        >
                          <b className={bid.deleted_at ? "disabled" : "link"}>
                            {bid.ad !== null ? bid.ad.owner_vehicle.title : "-"}
                          </b>
                        </td>
                        <td>
                          {bid.ad !== null
                            ? bid.ad.owner_vehicle.model !== null
                              ? bid.ad.owner_vehicle.model.brand_car.name
                              : "-"
                            : "-"}
                        </td>
                        <td>
                          {bid.ad !== null
                            ? bid.ad.owner_vehicle.model !== null
                              ? bid.ad.owner_vehicle.model.model_name
                              : "-"
                            : "-"}
                        </td>
                        <td>
                          <span>€ {bid?.ad?.public_price || "-"}</span> <br />
                          <span>€ {bid?.ad?.vat_price || 0}</span> <br />
                          <span>€ {bid?.ad?.total_price || "-"}</span>
                        </td>
                        <td>
                          <span>
                            {moment(bid.created_at)
                              .tz("Europe/Amsterdam")
                              .format("YYYY-MM-DD HH:mm")}
                          </span>
                          <br />
                          <div className="d-flex justify-content-center align-items-center gap-2">
                            <span
                              className={
                                amount < 30
                                  ? "end-status"
                                  : amount > 30 && amount < 60
                                  ? "progress-status"
                                  : amount > 60
                                  ? "active-status"
                                  : "active-status"
                              }
                            >
                              {bid.amount ? bid.amount : "-"}
                            </span>
                            {bid.auto_bid ? (
                              <span className="autobid-badge">Auto</span>
                            ) : null}
                            {bid.variation !== null && bid.variation > 35 ? (
                              <img
                                className="warningIcon"
                                src={warning}
                                title="Bid exceeded 35%"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <span>
                            {bid.variation !== null
                              ? bid.variation + " %"
                              : "-"}
                          </span>
                        </td>
                        <td>
                          {flag !== "" ? (
                            <img
                              src={flag.flag_thumbnail}
                              alt="flag"
                              className="countryflag"
                              title={flag.country}
                            />
                          ) : (
                            "-"
                          )}
                          <br />
                          {bidderId != null ? (
                            <a
                              href={handleNavigationforBidder(bid)}
                              target="_blank"
                            >
                              {name}
                            </a>
                          ) : (
                            { name }
                          )}
                          <br />
                          {Constant.TYPE_OF_PROFILE[bid.customer_type]}
                        </td>
                        <td>
                          {sellerId != null ? (
                            <a
                              href={handleNavigationforSeller(bid)}
                              target="_blank"
                            >
                              {sellerName}
                            </a>
                          ) : (
                            { sellerName }
                          )}
                          <br />
                          {bid.ad.owner_vehicle.current_sub !== undefined &&
                          bid.ad.owner_vehicle.current_sub !== null
                            ? "Company"
                            : "Private"}
                          {/* {Constant.TYPE_OF_PROFILE[bid.ad.auction.auction_event.display_for]} */}
                        </td>
                        <td>
                          <span
                            className={
                              "status " +
                              (bid.auctionStatus === "in_progress"
                                ? "status-active"
                                : bid.auctionStatus === "in_registration"
                                ? "status-registration"
                                : bid.auctionStatus === "upcoming"
                                ? "status-progress"
                                : bid.auctionStatus === "ended"
                                ? "status-end"
                                : bid.auctionStatus === "registration_ended"
                                ? "status-registration-end"
                                : "")
                            }
                            title={
                              bid.auctionStatus
                                .replace("_", " ")
                                .charAt(0)
                                .toUpperCase() +
                              bid.auctionStatus.replace("_", " ").slice(1)
                            }
                          ></span>
                          <br />
                          {bid.ad !== null
                            ? bid.ad.auction.auction_event.title.en
                            : "-"}
                        </td>
                        <td>
                          {bid.adStatus === "active" ? (
                            <span
                              className={"status status-active"}
                              title="Active"
                            ></span>
                          ) : (
                            <span
                              className={"status status-end"}
                              title="Inactive"
                            ></span>
                          )}
                        </td>
                        <td>
                          {bid.validated_at !== null ? (
                            <>
                              <span className="d-block">
                                {getName(bid.validated_by)
                                  .charAt(0)
                                  .toUpperCase() +
                                  getName(bid.validated_by).slice(1)}
                              </span>
                              <span className="d-block">
                                {moment(bid.validated_at)
                                  .tz("Europe/Amsterdam")
                                  .format("DD-MM-YYYY HH:mm")}
                              </span>
                            </>
                          ) : bidsAccess?.permissions.find(
                              (o) => o.slug === "update"
                            ) && bid.deleted_at === null ? (
                            bidsvalidated.length > 0 &&
                            getValidatedUser(bid.id) !== false ? (
                              <>
                                <span className="d-block">
                                  {getValidatedUser(bid.id)
                                    .validated_by.charAt(0)
                                    .toUpperCase() +
                                    getValidatedUser(bid.id).validated_by.slice(
                                      1
                                    )}
                                </span>
                                <span className="d-block">
                                  {moment(getValidatedUser(bid.id).validated_at)
                                    .tz("Europe/Amsterdam")
                                    .format("DD-MM-YYYY HH:mm")}
                                </span>
                              </>
                            ) : (
                              <input
                                type="checkbox"
                                checked={bid.id === bidId ? true : false}
                                onChange={(e) => {
                                  if (bid.id === bidId) {
                                    _bidId("");
                                  } else {
                                    _bidId(bid.id);
                                  }
                                }}
                              />
                            )
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoRecord />
                )}
              </tbody>
            )}
          </table>
        </div>
        {totalPage > 1 ? (
          <Pagination
            totalPages={totalPage}
            page={page}
            key={totalPage}
            onPageClick={(page) => setPage(page + 1)}
          />
        ) : (
          ""
        )}
      </div>
    </BidListWRapper>
  );
};

export default BidLists;
